<div class="dayBlock" [ngClass]="{'weekEnd' : bookingDay.dow === 7 || bookingDay.dow === 1, 
'dayBlockSelected': isThisDateSelected(),
'enabled':bookingDay.slots > 0}" (click)="onDayClicked()">

    <div class="monthText">{{date.toLocaleString('default', { month: 'long' })}}</div>

    <div class="dayText">{{date.getDate().toString()}}</div>

    <div>
        <i nz-icon nzType="check-circle" nzTheme="outline" *ngIf="bookingDay.slots > 0" style="color: var(--primaryColor)"></i>
        <i nz-icon nzType="minus-circle" nzTheme="outline" *ngIf="bookingDay.slots <= 0" style="color: rgba(255, 0, 0, 0.534);"></i>
    </div>


</div>