import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LinkService {

  private apiUrl = 'v1/bookings/';

  constructor(private http: HttpClient) { }

  public getLink(ref: string) {
    return this.http.get<{"href":string}>(environment.api_url + this.apiUrl + `link/${ref}`);
  }

  public getClientName(clientId: number) {
    return this.http.get<{"name":string}>(environment.api_url + this.apiUrl + `client/${clientId}`);
  }
}
