import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { BookingService } from '../booking.service';
// import * as html2pdf from 'html2pdf.js'
import { QuoteLine } from 'src/app/models/quote';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { QuoteService } from 'src/app/quote/quote.service';
import { NotificationService } from 'src/app/shared/notification.service';
import { ReportsService } from 'src/app/shared/reports.service';

@Component({
  selector: 'app-booking-success',
  templateUrl: './booking-success.component.html',
  styleUrls: ['./booking-success.component.less']
})
export class BookingSuccessComponent implements OnInit {

  @ViewChild('pdfElement') pdfElement: ElementRef;
  paramsSub: Subscription;
  tyreLines: QuoteLine[] = [];
  otherLines: QuoteLine[] = [];
  cashbackLines: QuoteLine[] = [];
  otherTotal: number = 0;
  cashbackTotal: number = 0;

  

  constructor(public bookingService: BookingService,
              private quoteService: QuoteService,
              private route: ActivatedRoute,
              private notification: NotificationService,
              private reports: ReportsService
              ) { }

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(
      params => {
        this.loadQuote(params['ref']);
      });
  }

  private loadQuote(ref: string) {
    this.quoteService.getQuote(ref).subscribe(
      val => {

        this.bookingService.selectedQuote = val;
        //split the line items
        this.tyreLines = this.bookingService.selectedQuote.quoteItems.filter(e => e.stockType === "TYRE");
        this.otherLines = this.bookingService.selectedQuote.quoteItems.filter(e => e.stockType !== "TYRE");
        this.otherTotal = this.otherLines.reduce((sum, current) => sum + (current.price * current.qty), 0);
        this.cashbackLines = this.otherLines.filter(e => e.msfId >= 27740 && e.msfId <= 27747);
        this.cashbackTotal = this.cashbackLines.reduce((sum, current) => sum + (current.price * current.qty), 0);
        this.otherLines = this.otherLines.filter(e => e.msfId < 27740 || e.msfId > 27747);
      },
      err => {
      }
    );
  }

  downloadPDF() {
        // this.notification.showSwalSuccess('','',500);

        // var element = this.pdfElement.nativeElement;
        //               element.style.display = "block";
        // var opt = {
        //   margin:       1,
        //   filename:     `QUOTE ${this.bookingService.selectedQuote.quoteRef}.pdf`,
        //   image:        { type: 'png', quality: 0.98 },
        //   html2canvas:  { scale: 1,  },
        //   jsPDF:        { unit: 'pt', format: 'a4', orientation: 'portrait' }
        // };
        // html2pdf().from(element).set(opt).save().then(res =>{
        // element.style.display = "none";});
        this.reports.downloadQuotePDF(this.bookingService.selectedQuote.quoteRef);
  }

  filterCashback(line: QuoteLine): QuoteLine[] {
    if (["GOODYEAR","MICHELIN","BFGOODRICH","PIRELLI","DUNLOP"].includes(line.brand )) {
      if (this.cashbackLines.length > 0)
        return this.cashbackLines.concat(this.otherLines);
      else
        return this.otherLines;
    }
    return this.otherLines;
  }

  calcOtherTotal(line: QuoteLine): number {
    if (["GOODYEAR","MICHELIN","BFGOODRICH","PIRELLI","DUNLOP"].includes(line.brand )) {
      if (this.cashbackLines.length > 0)
        return this.otherTotal;
      else
        return this.otherTotal - this.cashbackTotal;
    }

    return this.otherTotal - this.cashbackTotal;
  }

}
