<div nz-row nzJustify="center">
    <div nz-col nzXs="23" nzLg="18">

        <div nz-row nzJustify="center">
            <div nz-col nzSpan="12">
                <span style="font-weight: bold;" class="sfText">{{posQuote.sellerName}} </span> <br> Quote: {{posQuote.quoteRef}}
            </div>
        </div>

        <br>

        <div nz-row nzJustify="center">
            <div nz-col nzXs="24" nzLg="12">
                <i nz-icon nzType="user" nzTheme="outline"></i> : {{posQuote.custName}} | {{posQuote.custEmail}}
                <br>
            </div>
            <div nz-col nzXs="24" nzLg="12">
                <i nz-icon nzType="phone" nzTheme="outline"></i>: {{posQuote.custCell}} | <i nz-icon nzType="car" nzTheme="outline"></i> :
                <span *ngIf="linkedReg.mappingId !== 0">{{linkedReg.vReg}}</span>
                <span *ngIf="linkedReg.mappingId === 0"> ? </span>
            </div>

        </div>

        <br>

        <div nz-row nzJustify="center">
            <div nz-col nzXs="24" nzLg="8" class="space" *ngIf="showServices">
                <button nz-button nz-tooltip nzTooltipTitle="Add Services to your quote such as Wheel alignment/balancing ,New Valves and Nitrogen" (click)="AddServices()">
                  <span>
                    <i nz-icon nzType="tool" nzTheme="outline" class="sfText "></i>
                    Add Services
                  </span>
                </button>
            </div>


            <div nz-col nzXs="24" nzLg="8" class="space" *ngIf="linkedReg.mappingId === 0">
                <button nz-button (click)="onRegClick()" nz-tooltip nzTooltipTitle="Link Your vehicle to this quote by adding your registration no">
                  <span>
                    <i nz-icon nzType="warning" nzTheme="outline" class="sfText"></i>
                    Add Vehicle Reg
                  </span>
                </button>
            </div>


            <div nz-col nzXs="24" nzLg="8" class="space">
                <button nz-button nz-tooltip nzTooltipTitle="Update your Name,Email and Cell Details" (click)="showUpdateCustomerVisible = true">
                <span>
                  <i nz-icon nzType="edit" nzTheme="outline" class="sfText"></i>
                    Update Details
                  </span>
              </button>
            </div>

        </div>

        <br>
        <hr class="hr ">

        <div nz-row nzJustify="center">
            <ng-template *ngIf="!posQuote.bookingsEnabled then single; else double"> </ng-template>
            <ng-template #single>
                <div nz-col nzXs="24" nzLg="24" class="space">
                    <button nz-button class="btn-mobile" [class]="'btnBack'" (click)="reserveStock()" nz-tooltip nzTooltipTitle="We will reserve this stock for when you come in">
              <span>
                <i nz-icon nzType="check" nzTheme="outline" ></i>
                 Accept Quote
              </span>
            </button>
                </div>
            </ng-template>
            <ng-template #double>
                <div nz-col nzXs="24" nzLg="12" class="space">
                    <button nz-button class="btn-mobile" [class]="'btnBack'" (click)="reserveStock()" nz-tooltip nzTooltipTitle="We will reserve this stock for when you come in">
              <span>
                <i nz-icon nzType="check" nzTheme="outline"  ></i>
                 Accept Quote
              </span>
            </button>
                </div>
            </ng-template>
            <div nz-col nzXs="24" nzLg="12" *ngIf="posQuote.bookingsEnabled">
                <button nz-button class="btn-mobile" [class]="'btnBack'" (click)="bookSlot()" nz-tooltip nzTooltipTitle="Make a booking that suits your schedule for your vehicle">
            <span>
             <i nzType="left"  nz-icon nzType="calendar" nzTheme="outline"></i>
             Accept Quote & Book a Time Slot
            </span>
           </button>
            </div>
        </div>

        <br>

        <h5 style="color: black;">*All Prices Vat(Incl) in <span style="color:var(--primaryColor)"><b>ZAR</b></span></h5>

        <ng-template *ngIf="isMobile then Mobile; else Other "></ng-template>

        <ng-template #Mobile>
            <nz-card class="sfText ant-card-wide" style="margin-bottom: 25px;" nzBordered="false" nzTitle="Quote ">
                <div nz-row nzJustify="center">
                    <div nz-col nzSpan="24">
                        <nz-table *ngIf="showMain" [nzLoading]="isLoading" [nzOuterBordered]="false" nzSize="small" [nzData]="tyreLines" [nzHideOnSinglePage]="true">
                            <thead>
                                <tr>
                                    <th>Description</th>
                                    <th>Price</th>
                                    <th>Qty</th>
                                    <th>Total </th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let line of tyreLines; let i = index " style="font-size:12px ;">
                                    <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{line.brandLogo}}" class="brandImage" /> </td>
                                    <td>{{ line.price| number }}</td>
                                    <td>
                                        <nz-input-number class="input-number " [ngModel]="line.qty" [nzMin]="1" [nzMax]="500" [nzStep]="1" (ngModelChange)="updateLineItem(i,$event)"></nz-input-number>
                                    </td>
                                    <td>{{ line.price * line.qty | number }}</td>
                                    <td></td>
                                </tr>
                                <tr *ngFor="let exItem of filterCashback(tyreLines[0])">
                                    <td> {{exItem.description | titlecase}}</td>
                                    <td>{{ exItem.price | number }}</td>
                                    <td>{{exItem.qty}}</td>
                                    <td>{{exItem.price * exItem.qty | number }}</td>
                                    <td>
                                        <button *ngIf="exItem.removeable" nz-button class=" btn-remove" nz-tooltip nzTooltipTitle="Remove this Item from the quote" (click)="removeItem(exItem)">
                                <i nz-icon nzType="close" nzTheme="outline"></i>
                            </button>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                        <nz-table *ngIf="showService" [nzLoading]="isLoading" [nzOuterBordered]="false" nzSize="small" [nzPageSize]="100" [nzData]="otherLines" [nzHideOnSinglePage]="true">
                            <thead>
                                <tr>
                                    <th>Brand</th>
                                    <th>Description</th>
                                    <th>Qty</th>
                                    <th>Price (incl VAT)</th>
                                    <th>Total Price</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let exItem of otherLines">
                                    <td>
                                        {{exItem.brand}}
                                    </td>
                                    <td>
                                        {{exItem.description | titlecase}}
                                    </td>
                                    <td>
                                        {{exItem.qty}}
                                    </td>
                                    <td>
                                        R <span class="sfText">  {{ exItem.price }} </span>
                                    </td>
                                    <td>
                                        R <span class="sfText"> {{exItem.price * exItem.qty}}</span>
                                    </td>
                                    <td>
                                        <button *ngIf="exItem.removeable" nz-button class=" btnremove" nz-tooltip nzTooltipTitle="Click to Remove Item" (click)="removeItem(exItem)">
                                  <i nz-icon nzType="close" nzTheme="outline"></i>
                              </button>
                                    </td>
                                </tr>
                            </tbody>
                        </nz-table>
                    </div>
                </div>

            </nz-card>
        </ng-template>

        <ng-template #Other>
            <div nz-row>
                <div nz-col nzSpan="24">
                    <div nz-row nzJustify="center">
                        <div nz-col nzSpan="24">
                            <nz-table *ngIf="showMain" [nzLoading]="isLoading" [nzOuterBordered]="false" nzSize="small" [nzPageSize]="100" [nzData]="tyreLines" [nzHideOnSinglePage]="true">
                                <thead>
                                    <tr>
                                        <th>Brand</th>
                                        <th>Description</th>
                                        <th>Qty</th>
                                        <th>Price (incl VAT)</th>
                                        <th>Total Price</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let line of tyreLines; let i = index " class="row">
                                        <td>
                                            <div *ngIf="!line.brandLogo; else brandLogoImage">{{ line.brand }}</div>
                                            <ng-template #brandLogoImage>
                                                <img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{line.brandLogo}}" class="brandImage" alt="{{line.brand}}" nz-tooltip nzTooltipTitle="{{line.brand}}" nzTooltipPlacement="right" />
                                            </ng-template>
                                        </td>
                                        <td>
                                            {{ line.description }}
                                        </td>
                                        <td>
                                            <nz-input-number class="input-number " [ngModel]="line.qty" [nzMin]="1" [nzMax]="500" [nzStep]="1" (ngModelChange)="updateLineItem(i,$event)">
                                            </nz-input-number>
                                        </td>
                                        <td>
                                            {{ line.price }}
                                        </td>
                                        <td>
                                            {{ line.price * line.qty}}
                                        </td>
                                        <td>
                                        </td>
                                    </tr>
                                    <tr *ngFor="let exItem of filterCashback(tyreLines[0])">
                                        <td>
                                            {{exItem.brand}}
                                        </td>
                                        <td>
                                            {{exItem.description | titlecase}}
                                        </td>
                                        <td>
                                            {{exItem.qty}}
                                        </td>
                                        <td>
                                            {{ exItem.price }}
                                        </td>
                                        <td>
                                            {{exItem.price * exItem.qty}}
                                        </td>
                                        <td>
                                            <button *ngIf="exItem.removeable" nz-button class="btn-remove" nz-tooltip nzTooltipTitle="Remove this Item from the quote" (click)="removeItem(exItem)">
                                  <i nz-icon nzType="close" nzTheme="outline"></i>
                              </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                            <nz-table *ngIf="showService" [nzLoading]="isLoading" [nzOuterBordered]="false" nzSize="small" [nzPageSize]="100" [nzData]="otherLines" [nzHideOnSinglePage]="true">
                                <thead>
                                    <tr>
                                        <th>Brand</th>
                                        <th>Description</th>
                                        <th>Qty</th>
                                        <th>Price (incl VAT)</th>
                                        <th>Total Price</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let exItem of otherLines">
                                        <td>
                                            {{exItem.brand}}
                                        </td>
                                        <td>
                                            {{exItem.description | titlecase}}
                                        </td>
                                        <td>
                                            {{exItem.qty}}
                                        </td>
                                        <td>
                                            {{ exItem.price }}
                                        </td>
                                        <td>
                                            {{exItem.price * exItem.qty}}
                                        </td>
                                        <td>
                                            <button *ngIf="exItem.removeable" nz-button class="btnremove" nz-tooltip nzTooltipTitle="Click to Remove Item" (click)="removeItem(exItem)">
                                      <i nz-icon nzType="close" nzTheme="outline"></i>
                                  </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </nz-table>
                        </div>
                    </div>
                </div>
            </div>
        </ng-template>

        <div nz-row nzJustify="center">
            <div nz-col nzXs="24" nzLg="12">
                <span class="total"> Total :</span>
                <b class="sfText">R {{totalPriceInc() | number}}</b>
            </div>
        </div>
    </div>
</div>

<!--Details Modal-->
<nz-modal [(nzVisible)]="showUpdateCustomerVisible" nzClosable="true" nzTitle="Update Details" (nzOnCancel)="showUpdateCustomerVisible = false;" (ngSubmit)="updateCustomer()">
    <div *nzModalContent>
        <form nz-form [formGroup]="customerForm">
            <nz-form-item>
                <nz-form-control [nzErrorTip]="'Cell Number required'">
                    <nz-input-group nzPrefixIcon="phone" class="solid">
                        <input class="inputdiv" (keypress)="numberInput($event)" maxlength="10" nz-input name="cell" formControlName="cell" placeholder="Cell Number">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="'Name required'">
                    <nz-input-group nzPrefixIcon="user" class="solid">
                        <input class="inputdiv" nz-input name="name" formControlName="name" placeholder="First Name">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
            <nz-form-item>
                <nz-form-control [nzErrorTip]="'Email required'">
                    <nz-input-group nzPrefixIcon="mail" class="solid">
                        <input class="inputdiv" nz-input name="email" formControlName="email" placeholder="Email Address">
                    </nz-input-group>
                </nz-form-control>
            </nz-form-item>
        </form>
    </div>
    <div *nzModalFooter>
        <button nz-button class="btn-mobile btnBack" type="submit" (click)="updateCustomer()">
            Save
        </button>
    </div>
</nz-modal>


<!--Vreg Modal-->
<nz-modal [(nzVisible)]="showRegModal" nzTitle="Vehicle Registration" (nzOnCancel)="showRegModal = false" [nzFooter]="null">
    <div *nzModalContent>
        <div nz-row nzJustify="center">
            <div nz-col nzXs="24" nzLg="8">
                <nz-spin [nzSpinning]="isLoadingLinkedReg">
                    <button nz-button *ngFor="let item of customerReg" class=" vreg-option" (click)="linkRegToQuote(item.mappingId)">
                    <span>
                    <i nz-icon nzType="car" nzTheme="outline"></i> <span style="text-transform: uppercase;"> {{item.vReg}}</span>
                </span>
              </button>
                    <div style="text-align: center;color: darkgoldenrod;" *ngIf="customerReg.length === 0">
                        <i nz-icon nzType="warning" nzTheme="outline" style="font-size: 3rem;"></i><br /> No linked registrations
                    </div>
                    <button nz-button class="btn-mobile btnBack" style="margin-top: 10px;" (click)="onAddVehicleClick()">
                  Add new registration
              </button>
                </nz-spin>
            </div>
        </div>
    </div>

</nz-modal>

<nz-modal [(nzVisible)]="showAddVehicleModal" nzTitle="Add New Vehicle" (nzOnCancel)="showAddVehicleModal = false">
    <div *nzModalContent>
        <nz-spin [nzSpinning]="isLoadingAddVehicle">
            <form nz-form [formGroup]="addVehicleForm">
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Vehicle Registration Required'">
                        <nz-input-group nzPrefixIcon="car">
                            <input class="inputdiv" addVehicleForm nz-input name="vehReg" formControlName="vehReg" placeholder="Vehicle Registration">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Tyre Width Required'">
                        <nz-input-group nzPrefixIcon="car">
                            <input class="inputdiv" (keypress)="numberInput($event)" nz-input name="vehWidth" formControlName="vehWidth" placeholder="Width">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Tyre Profile Required'">
                        <nz-input-group nzPrefixIcon="car">
                            <input class="inputdiv" maxlength="3" nz-input name="vehProfile" formControlName="vehProfile" placeholder="Profile">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
                <nz-form-item>
                    <nz-form-control [nzErrorTip]="'Rim Size Required'">
                        <nz-input-group nzPrefixIcon="car">
                            <input class="inputdiv" maxlength="2" nz-input name="vehRim" formControlName="vehRim" placeholder="Rim">
                        </nz-input-group>
                    </nz-form-control>
                </nz-form-item>
            </form>
        </nz-spin>
    </div>
    <div *nzModalFooter>
        <button nz-button class="btn-mobile btnBack" (click)="addVehicle()">
            <i nz-icon nzType="plus" nzTheme="outline"></i>
            
            Add
        </button>
    </div>
</nz-modal>