import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Bookingday } from 'src/app/models/booking.models';
import { BookingService } from '../../booking.service';

@Component({
  selector: 'app-calendar-day-block',
  templateUrl: './calendar-day-block.component.html',
  styleUrls: ['./calendar-day-block.component.less']
})
export class CalendarDayBlockComponent implements OnInit {

  date: Date = new Date();
  @Input() bookingDay: Bookingday;
  @Output() dayClicked = new EventEmitter<Date>();


  constructor(private bookingService: BookingService) { }

  ngOnInit(): void {
    this.date = new Date(this.bookingDay.date);   
    
  }

  isThisDateSelected(): boolean {
    return this.bookingService.selectedDate.toDateString() == this.date.toDateString();
  }

  onDayClicked() {
    if (this.bookingDay.slots > 0)
      this.dayClicked.emit(this.date);
  }

}
