<nz-layout class="app-layout">
    <nz-header>
        <div nz-row [nzJustify]="'center'">
            <div nz-col>
                <img src="./../assets/images/theme_logo/{{themeService.themeLogo()}}" class="brandImagesingle">
            </div>
        </div>
    </nz-header>
    <br>
    <nz-content>
        <div class="inner-content">
            <br>
            <router-outlet></router-outlet>
        </div>
    </nz-content>
    <nz-footer><b> Powered By StockFinder &copy;2024 </b></nz-footer>
</nz-layout>