import { Time } from "@angular/common";

export class Bookingday {    
    constructor(public date: Date,
                public dow: number,
                public weekDay: string,
                public slots: number) {
                
    }
}

export class BookingSlot {    

    constructor(public date: Date,
                public dow: number,
                public slotTime: string,
                public openSlots: number) {
                
    }
}

export class QuoteBooking {
    public bookingId: number;
    public dateBooked: Date;
    public hourBooked: number;
    public dtCreated: Date;
    public customerId: number;
    public custName: string;
    public custCell: string;
    public custEmail: string;
    public clientId: number;
    public clientName: string;
}