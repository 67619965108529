import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ReportBase64 } from '../models/reports';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  // private apiUrl = 'v1/reports/';
  private apiUrl = 'v2/reports/';
  private apiV1Url = 'v1/reports/';

  constructor(
    private http: HttpClient,
    private notification: NotificationService
  ) { }


  private downloadFile(base64:any,fileName:any){
    // const src = `data:application/pdf;base64, ${base64}`;
    // const link = document.createElement("a")
    // link.href = src
    // link.download = fileName
    // link.click()
    // link.remove()


    // Fix For all browsers including Mobile
    var element = document.createElement('a');
    var clearUrl = base64.replace(`data:application/pdf;base64,${base64}`);
    element.setAttribute('href', 'data:application/octet-stream;base64,' + encodeURIComponent(clearUrl));
    element.setAttribute('download', fileName + '.pdf');
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);

  }

  private printFile(content: any) {
    const byteCharacters = atob(content);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], {type: 'application/pdf'});
    const blobUrl = URL.createObjectURL(blob);

    let tab = window.open(blobUrl, "_blank");
    let k = setTimeout(() => {
          tab?.print();
          clearTimeout(k);
        }, 500);
  }


  public invoiceBase64(ref: string) {
    return this.http.get<ReportBase64>( environment.api_url + this.apiV1Url + "invoice/ref/" + ref);
  }

  public quoteBase64(quoteRef: string) {   
    return this.http.get<ReportBase64>(environment.api_url + this.apiUrl  + `quote/ref/${quoteRef}`);  
  }

  public downloadQuotePDF(quoteRef: string) {
    this.quoteBase64(quoteRef).subscribe(
      val => {
        this.downloadFile(val.content, val.title);
      },
      _ => {
        this.notification.showWarning("Unable to download quote")
      }
    )
  }

  public printQuotePDF(quoteRef: string) {
    this.quoteBase64(quoteRef).subscribe(
      val => {
        this.printFile(val.content);
      },
      _ => {
        this.notification.showWarning("Unable to print quote")
      }
    )
  }

  public downloadInvoicePDF(ref: string) {
    this.invoiceBase64(ref).subscribe(
      val => {
        this.downloadFile(val.content, val.title);
      },
      _ => {
        this.notification.showWarning("Unable to download invoice")
      }
    )
  }

  public printInvoicePDF(ref: string) {
    this.invoiceBase64(ref).subscribe(
      val => {
        this.printFile(val.content);
      },
      _ => {
        this.notification.showWarning("Unable to print invoice")
      }
    )
  }


}
