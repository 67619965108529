<div nz-row nzJustify="center">
    <div nz-col nzXs="24" nzLg="8">
        <div>
            <nz-result nzStatus="success" nzTitle="Successfully Reserved Stock" [nzSubTitle]=messageTemplate>
            </nz-result>
            <ng-template #messageTemplate>
                Fitment Centre: {{bookingService.selectedQuote.sellerName}}<br /> {{bookingService.selectedQuote.sellerCell}} | {{bookingService.selectedQuote.sellerEmail}}<br><br>
                <b>Quote Available Here </b><br><br>
                <div nz-row nzJustify="center">
                    <div nz-col nzXs="24" nzLg="12">
                        <button nz-button (click)="downloadPDF()">
                <i nzType="left"  nz-icon nzType="download" nzTheme="outline"></i>
              </button>
                    </div>
                </div>
            </ng-template>
        </div>
    </div>
</div>


<!-- <div  #pdfElement class="pdfElement" style="display: none;">
  <div nz-row nzJustify="center">
      <div nz-col nzSpan="12">
          <span style="font-weight: bold;" class="sfText">QUOTATION</span><br />
          <b>Ref:</b> {{this.bookingService.selectedQuote.quoteRef}}<br />
          <b>Date:</b> {{this.bookingService.selectedQuote.dateQuoted}}
          <br /><br />
          <b>To:</b> {{this.bookingService.selectedQuote.custName}} <br />
          {{this.bookingService.selectedQuote.custCell}}
      </div>
      <div nz-col nzSpan="12" >
          <span style="font-weight: bold;" class="sfText">DEALER</span><br />
          {{ this.bookingService.selectedQuote.sellerName }} <br />
          {{ this.bookingService.selectedQuote.sellerEmail }} <br />
          <b>{{ this.bookingService.selectedQuote.sellerCell }}  </b> <br />
          <div *ngIf="this.bookingService.selectedQuote.sellerBankingDetails" style="white-space:pre-wrap;" >{{this.bookingService.selectedQuote.sellerBankingDetails}}</div>
      </div>
      <div nz-col nzSpan="12">
        <div *ngIf="this.bookingService.selectedQuote.user">
          <span style="font-weight: bold;" class="sfText">Salesman</span><br />
          {{this.bookingService.selectedQuote.user.userName}}<br/>
       <b>{{this.bookingService.selectedQuote.user.userCell }} </b>
      </div>
      </div>
  </div>
  <hr>
  <div nz-row nzJustify="center">
    <div nz-col nzSpan="24">
    <div *ngFor="let line of tyreLines; let i = index" style="page-break-inside: avoid;">
        <table>
            <thead>
                <tr style="background-color: black; color: white; border: 1px solid black;">
                    <td style="width: 80px"></td>
                    <td style="width: 125px">BRAND</td>
                    <td style="width: 325px">DESCRIPTION</td>
                    <td style="width: 60px">PRICE</td>
                    <td style="width: 50px">QTY</td>
                    <td style="width: 65px">TOTAL</td>
                </tr>
            </thead>
            <tbody>
                <tr style="border: 1px solid grey;">
                    <td><img src="https://sftptyres.blob.core.windows.net/brands-images/brands/{{line.brandLogo}}" class="brandImage"></td>
                    <td>
                        {{line.brand}}
                    </td>
                    <td>{{line.description}}</td>
                    <td>R {{line.price}}</td>
                    <td>{{line.qty}}</td>
                    <td>R {{line.price * line.qty}}</td>
                </tr>
                <tr style="border: 1px solid grey" *ngFor="let exItem of filterCashback(line)">
                    <td></td>
                    <td>
                        {{exItem.brand}}
                    </td>
                    <td>{{exItem.description | titlecase}}</td>
                    <td>R {{exItem.price}}</td>
                    <td>{{exItem.qty}}</td>
                    <td>R {{exItem.price * exItem.qty}}</td>
                </tr>
                <tr>
                    <td colspan="5" style="text-align: right;padding-right: 20px;">
                        <b>TOTAL</b>
                    </td>
                    <td>
                        <b>R {{calcOtherTotal(line) + line.price * line.qty}}</b>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    </div>
  </div>
</div> -->