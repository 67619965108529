
<div nz-row nzJustify="center">
  <div nz-col nzXs="23"  nzLg="23">
    <div *ngIf="hasQuoteDetails; else loadingTemplate">
      <div  [ngClass]="{'booking' : !bookingCompleted}"  >
          <div nz-row [nzJustify]="'center'" *ngIf="!bookingCompleted">
              <div nz-col nzSpan="24">
                  <h5 ><b>BOOK A TIME SLOT</b></h5>
                  <hr />
              </div>
              <div nz-col nzXs="24" nzSm="24" nzMd="12">
                <app-booking-calendar-month [clientId]="bookingService.selectedQuote.sellerId"></app-booking-calendar-month>
              </div>
              <div nz-col nzXs="24" nzSm="24" nzMd="12">
                <app-booking-calendar-day [clientId]="bookingService.selectedQuote.sellerId" (timeClicked)="onClickTime($event)"></app-booking-calendar-day>
              </div>
          </div>
      </div>
    <app-booking-success *ngIf="bookingCompleted"></app-booking-success>
  </div>

    <ng-template #loadingTemplate>
        <app-loading></app-loading>
    </ng-template>

  </div>
</div>

<nz-modal [(nzVisible)]="isConfirmVisible"
          nzClosable="Confirm Booking"
          nzTitle="Confirm Booking" 
          (nzOnCancel)="isConfirmVisible = false" 
          (nzOnOk)="onClickConfirm()">
    <div *nzModalContent>
      <span class="sfText"> Date: </span> <span class="whitetext">{{bookingService.selectedDateStr}}</span> <br />
      <span class="sfText">  Time: </span><span class="whitetext">{{bookingService.selectedTime.substring(0,5)}}</span>  <br /><br />
        <strong class="whitetext">Are you sure you want to confirm this booking?</strong>
    </div>
    <div *nzModalFooter>
      <button nz-button class="btn-mobile btnBack"  type="submit" (click)="onClickConfirm()" >
          Yes
      </button>
  </div>
  </nz-modal>


