
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BookingSlot } from '../models/booking.models';
import { QuoteService } from '../quote/quote.service';
import { NotificationService } from '../shared/notification.service';
import { BookingService } from './booking.service';

@Component({
  selector: 'app-booking',
  templateUrl: './booking.component.html',
  styleUrls: ['./booking.component.less']
})
export class BookingComponent implements OnInit, OnDestroy {

  isConfirmVisible = false;
  paramsSub: Subscription;
  hasQuoteDetails = false;
  bookingCompleted = false;
  // selectedQuote: Quote;

  constructor(
    public bookingService: BookingService, 
    public quoteService: QuoteService, 
    private route: ActivatedRoute,
    private notification: NotificationService) 
  { }

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(
      params => { 
        this.loadQuote(params['ref']);
      });
  }

  ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }

  private loadQuote(ref: string) {
    this.quoteService.getQuote(ref).subscribe(
      val => {
        this.hasQuoteDetails = true;
        this.bookingService.selectedQuote = val;
      },
      err => {
        this.notification.handleError(err);
      }
    );
  }

  onClickConfirm() {
    //save booking
    this.bookingService.confirmBooking().subscribe(
      val => {
        this.isConfirmVisible = false;
        //redirect to confirmation
        this.bookingCompleted = true;
      },
      err => {
        this.notification.handleError(err);
      }
    )
    
  }

  onClickTime(slot: BookingSlot) {
    this.bookingService.selectedTime = slot.slotTime;
    this.isConfirmVisible = true;
    
  }

}
