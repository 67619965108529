import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { QuoteComponent } from './quote/quote.component';
import { ViewQuoteComponent } from './quote/view-quote/view-quote.component';
import { ModifyQuoteComponent } from './quote/modify-quote/modify-quote.component';
import { PublicQuoteComponent } from './public-quote/public-quote.component';
import { BookingComponent } from './booking/booking.component';
import { StockReservedComponent } from './quote/stock-reserved/stock-reserved.component';
import { LinkComponent } from './link/link.component';
import { CallBackComponent } from './quote/call-back/call-back.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { LinkWhatsappComponent } from './link-whatsapp/link-whatsapp.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: '/quote' },
  { path: 'quote', component: QuoteComponent },
  { path: 'quote/:ref', component: ViewQuoteComponent },
  { path: 'quote/:token/generate', component: PublicQuoteComponent},
  { path: 'quote/modify/:ref', component: ModifyQuoteComponent },
  { path: 'quote/:ref/callback', component: CallBackComponent},
  { path: 'michelin/:token', component: PublicQuoteComponent},
  { path: 'michelin-quote', component: PublicQuoteComponent},
  { path: 'quote/:ref/booking', component: BookingComponent},
  { path: 'quote/:ref/reserved', component: StockReservedComponent},
  { path: 'quote/:ref/:salesman', component: ViewQuoteComponent},
  { path: 'link/:linktoken', component: LinkComponent},
  { path: 'invoice/:ref', component: InvoiceComponent},
  { path: 'l/:clientId', component: LinkWhatsappComponent},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
