

//The following class is added because people can't stick to a standard, so one call names it quote_lines (LIKE ALL OTHERS) but the initial BS class names it quoteItems for some weird reason so the class can't be reused.
export class GeneratedQuote {
    quoteId: number
    quoteRef: string
    sellerId: number
    sellerName: string
    isWhatsapp: boolean
    quote_lines: ExportedQuoteLine[]
}

export class ExportedQuoteLine {
    description_Long: string
    msfid: number
}

export class Quote {
    quoteId: number;
    quoteRef: string;
    statusId: number;
    sellerId: number;
    sellerAddress: string;
    sellerName: string;
    sellerEmail: string;
    sellerCell: string;
    // sellerBankingDetails: string;
    showBankingDetails:boolean
    beneficiaryName:string = ""
    bankName:string = ""
    branchCode:number = 0
    accountType:string = ""
    accountNumber: number = 0
    dateQuoted: string;
    custName: string;
    custCell: string;
    custEmail: string;
    running_total: string;
    buyerId: number;
    quoteItems: QuoteLine[];
    custId:number;
    buyerLogo: string;
    sellerLogo: string;
    bookingsEnabled: boolean;
    stateId: number;
    comment: string;
    user:QuoteUser = new QuoteUser()
    licenseId:number;
    waEnabled:boolean;

}


export class QuoteUser{
    userCell: string
    userName: string
}

export class QuoteLine {
    quoteLineId: number;
    quoteRef: string;
    sellerId: number;
    msfId: number;
    description: string;
    description_Long: string;
    qty: number;
    price: number;
    brand:string;
    brandLogo: string;
    stockType: string;
    removeable:boolean;
    width:string;
    profile:string;
    rim:string;
    rank: number;
    lineIndex:number;
    optionId: number;
}

export class ServiceLine {
    quoteId:number;
    sellerId:number;
    msfid :number;
    cost:number
    price:number
    qty :number
}


export class AcceptOption {
    Tyre : QuoteLine[];
    OtherItems: QuoteLine[];
}

export class AcceptService {
    OtherItems: QuoteLine[];
}


export class VehicleReg {
    mappingId: number
    vReg: string
  }

export class CustomerVehicle {
    vehReg: string
    vehWidth: number
    vehProfile: number
    vehRim: number
}

export class CustomerDetails {
    customerId: number
    name: string
    email: string
    cell: string
}

export class CustomerAdd {
    quoteId: number
    name: string
    email: string
    cell: string
}

export class LineQty {
    quoteLineId: number
    qty: number
}




