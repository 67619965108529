import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { Subscription } from 'rxjs';
import { BookingSlot } from 'src/app/models/booking.models';
import { NotificationService } from 'src/app/shared/notification.service';
import { BookingService } from '../booking.service';

@Component({
  selector: 'app-booking-calendar-day',
  templateUrl: './booking-calendar-day.component.html',
  styleUrls: ['./booking-calendar-day.component.less']
})
export class BookingCalendarDayComponent implements OnInit, OnDestroy {

  private dateSub: Subscription | undefined; 

  @Output() timeClicked = new EventEmitter<BookingSlot>();
  @Input() clientId: number;

  isLoading = false;
  slots: BookingSlot[] = [];
  displayDate: string = "";

  constructor(private bookingService: BookingService, private notification: NotificationService) { }

  ngOnInit(): void {
    this.dateSub = this.bookingService.selectedDateChanged.subscribe( (date: Date) => {this.loadSlots(date);} );
    this.loadSlots(new Date()); //This is necessary because the subscription will be set up AFTER the first emit happens
  }

  ngOnDestroy(): void {
    if (this.dateSub)
      this.dateSub.unsubscribe();
  }

  onItemClick(item: BookingSlot){    
    //console.log("DONE",item.slotTime.toString());
    ///this.message.create('success',`You selected ${item.hod.toString().padStart(2,"0")}h00 on date ${this.bookingService.selectedDateStr}`);
    if (item.openSlots <= 0) {
      this.notification.showWarning(item.slotTime.substring(0,5) + " is fully booked. Please select another time.");
    }
    else {
      this.timeClicked.emit(item);
    }
    
  }

  private loadSlots(date: Date): void {
    this.isLoading = true;
    const datePipe: DatePipe = new DatePipe(this.bookingService.locale);
    this.displayDate = datePipe.transform(this.bookingService.selectedDate,'dd MMM')!;
    this.bookingService.getSlots(this.clientId)
        .subscribe(data => {          
          this.displaySlots(data);
        },
        err => {
          this.notification.handleError(err);
        }
      );
  }

  private displaySlots(data: BookingSlot[]) {
    this.slots.length = 0 //to clear the array, with all references to it.
    for (var i of data) {
      this.slots.push(i);
    }
    this.isLoading = false;
  }


}
