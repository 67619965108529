import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from 'src/environments/environment';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class SharedService {

  private apiUrl = 'v1/bookings/';

  constructor(private deviceService: DeviceDetectorService) { }


  getfranchisecolors()
  {

  }

  isMobile(): boolean {
    return this.deviceService.isMobile();
  }
}
