import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { BookingService } from 'src/app/booking/booking.service';
import { QuoteService } from '../quote.service';
import { NotificationService } from 'src/app/shared/notification.service';
// import * as html2pdf from 'html2pdf.js'
import { QuoteLine } from 'src/app/models/quote';
import { ReportsService } from 'src/app/shared/reports.service';



@Component({
  selector: 'app-stock-reserved',
  templateUrl: './stock-reserved.component.html',
  styleUrls: ['./stock-reserved.component.less']
})
export class StockReservedComponent implements OnInit, OnDestroy {

  @ViewChild('pdfElement') pdfElement: ElementRef;
  paramsSub: Subscription;
  hide: boolean = false;
  tyreLines: QuoteLine[] = [];
  otherLines: QuoteLine[] = [];
  cashbackLinesGYPD: QuoteLine[] = [];
  cashbackLinesMIBF: QuoteLine[] = [];
  
  otherTotal: number = 0;
  cashbackTotalGYPD: number = 0;
  cashbackTotalMIBF: number = 0;
  quoteReferenceNumber: string = '';


  constructor(public bookingService: BookingService,
              private quoteService: QuoteService,
              private route: ActivatedRoute,
              private notification: NotificationService,
              private reports: ReportsService

              ) { }

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(
      params => {
        this.quoteReferenceNumber = params['ref'];
        this.loadQuote(params['ref']);
      });
  }

  ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }

  private loadQuote(ref: string) {
    this.quoteService.getQuote(ref).subscribe(
      val => {

        this.bookingService.selectedQuote = val;
        //split the line items
        this.tyreLines = this.bookingService.selectedQuote.quoteItems.filter(e => e.stockType === "TYRE");
        this.otherLines = this.bookingService.selectedQuote.quoteItems.filter(e => e.stockType !== "TYRE");
        //this.otherTotal = this.otherLines.reduce((sum, current) => sum + (current.price * current.qty), 0);
        this.cashbackLinesMIBF = this.otherLines.filter(e => [29714,29715,29716,29717,29718,29719,29720,29721].includes(e.msfId));
        this.cashbackLinesGYPD = this.otherLines.filter(e => [27747,27746,27745,27744,27743,27742,27741,27740].includes(e.msfId));

        this.cashbackTotalGYPD = this.cashbackLinesGYPD.reduce((sum, current) => sum + (current.price * current.qty), 0);
        this.cashbackTotalMIBF = this.cashbackLinesMIBF.reduce((sum, current) => sum + (current.price * current.qty), 0);
        this.otherLines = this.otherLines.filter(e => !this.cashbackLinesMIBF.includes(e)).filter(e => !this.cashbackLinesGYPD.includes(e));
        this.otherTotal = this.otherLines.reduce((sum, current) => sum + (current.price * current.qty), 0);
      },
      err => {
      }
    );
  }

  downloadPDF() {
        // this.notification.showSwalSuccess('','',500);
        // this.hide = true;
        // var element = this.pdfElement.nativeElement;
        //               element.style.display = "block";
        // var opt = {
        //   margin:       1,
        //   filename:     `QUOTE ${this.bookingService.selectedQuote.quoteRef}.pdf`,
        //   image:        { type: 'png', quality: 0.98 },
        //   html2canvas:  { scale: 1,  },
        //   jsPDF:        { unit: 'pt', format: 'a4', orientation: 'portrait' }
        // };
        // html2pdf().from(element).set(opt).save().then(res =>{
        // element.style.display = "none";});
        this.reports.downloadQuotePDF(this.quoteReferenceNumber);
  }

  cashbackBrandsGYPD = ["GOODYEAR","PIRELLI","DUNLOP"];
  cashbackBrandsMIBF = ["MICHELIN","BFGOODRICH","BFPASSENGER"];

  filterCashback(line: QuoteLine): QuoteLine[] {
    if (this.cashbackBrandsGYPD.includes(line.brand )) {
      if (this.cashbackLinesGYPD.length > 0)
        return this.cashbackLinesGYPD.concat(this.otherLines);
      else
        return this.otherLines;
    };
    if (this.cashbackBrandsMIBF.includes(line.brand )) {
      if (this.cashbackLinesMIBF.length > 0)
        return this.cashbackLinesMIBF.concat(this.otherLines);
      else
        return this.otherLines;
    };
    
    return this.otherLines;
  }

  calcOtherTotal(line: QuoteLine): number {
    if (this.cashbackBrandsGYPD.includes(line.brand )) {
      if (this.cashbackLinesGYPD.length > 0)
        return this.otherTotal + this.cashbackTotalGYPD;
      else
        return this.otherTotal;
    }
    if (this.cashbackBrandsMIBF.includes(line.brand )) {
      if (this.cashbackLinesMIBF.length > 0)
        return this.otherTotal + this.cashbackTotalMIBF;
      else
        return this.otherTotal;
    }

    return this.otherTotal;
  }

}
