import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LinkService } from '../link/link.service';
import { NotificationService } from '../shared/notification.service';

@Component({
  selector: 'app-link-whatsapp',
  templateUrl: './link-whatsapp.component.html',
  styleUrls: ['./link-whatsapp.component.less']
})
export class LinkWhatsappComponent implements OnInit, OnDestroy {

  private paramsSub: Subscription;

  constructor(private route: ActivatedRoute, private linkService: LinkService, private router: Router, private notification: NotificationService) { }

  ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }

  ngOnInit(): void { 
    this.paramsSub = this.route.params.subscribe(
      params => {
        this.linkService.getClientName(params["clientId"]).subscribe(
          val => {
            window.location.href = `https://wa.me/27664532826/?text=Please+send+me+a+Quote+from+${val.name.replace(/ /g,'+',)}`;

          },
          err => {
            this.notification.ShowBlockingPopup("Error", "Invalid Link");
          })
      },
      error => {
        this.notification.ShowBlockingPopup("Error", "Invalid Link");
      }
    )

  }

}
