import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { QuoteService } from '../quote/quote.service';

@Component({
  selector: 'app-public-quote',
  templateUrl: './public-quote.component.html',
  styleUrls: ['./public-quote.component.less']
})
export class PublicQuoteComponent implements OnInit, OnDestroy {

  constructor(private route: ActivatedRoute, private quoteService: QuoteService, private router: Router) { }

  paramsSub: Subscription;
  queryParamsSub: Subscription;

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(
      params => {
        if (this.queryParamsSub) this.queryParamsSub.unsubscribe();
        this.queryParamsSub = this.route.queryParams.subscribe(
          qparams => {
            let queryParamsString: string[] = [];
            let msfidOptions: string = "";
            for (const p in qparams) {
              queryParamsString.push(p + "=" + qparams[p]);
              //store the msfid requests
              if (p === "msfIds") {
                msfidOptions = qparams[p];
              }
            }
            let token = params['token'];
            if (!token) token = 'c13rofl';
            this.quoteService.generateQuote(token, queryParamsString).subscribe(
              val => {
                
                let tyre = "MICHELIN";
                for (let reqMsfid of msfidOptions.split(",")) {
                  
                  for (let tyreItem of val.quote_lines) {
                    
                    if (tyreItem.msfid === +reqMsfid) {
                      tyre = tyreItem.description_Long;
                    }
                  }
                }
                
                
                //check activation of quote
                if (val.isWhatsapp === true) {
                  window.location.href = `https://api.whatsapp.com/send/?phone=27664532826&text=I%20would%20like%20a%20quote%20from%20"${val.sellerName}".%20Please%20send%20a%20quote%20for%20these%20tyres:${tyre}%20ref%20(${val.quoteRef})&app_absent=0`
                  return;
                }
                
                this.router.navigate(["/quote", val.quoteRef])
              }
            )
          }
        )

      }
    )
  }




  ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
    this.queryParamsSub.unsubscribe();
  }

}
