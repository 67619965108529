import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuditEntry } from '../models/audit-entry';
import { NotificationService } from '../shared/notification.service';

@Injectable({
  providedIn: 'root'
})
export class AuditService {

  constructor(private http: HttpClient, private notification: NotificationService) { }

  posAuditLog: AuditEntry[] = [];

  public addAction(item: AuditEntry) {
    if (this.posAuditLog.length > 0) {
        let lastItem = this.posAuditLog[this.posAuditLog.length - 1];
        if (lastItem.actionId === item.actionId && lastItem.quoteId === item.quoteId && lastItem.foreignKeyId === item.foreignKeyId)
        {
            lastItem.valueNew = item.valueNew;
        }
        else {
            this.posAuditLog.push(item);
        }
    }
    else {
        this.posAuditLog.push(item);
    } 
}

public saveActions(userId: number = 0) {
    //write to api
    
    this.SaveActionsSub(userId).subscribe(() => {})
    
}

public SaveActionsSub(userId: number = 0) {
    //update current array to writing
    const now = new Date();
    this.posAuditLog.forEach(x => {
        x.isWriting = true;
        x.writingTime = now;        
    });
    
    return this.http.post(environment.api_url + `v1/bookings/saveQuoteActions/${userId}`,this.posAuditLog.filter(x => x.writingTime === now)) //only send the items that have not been sent
    .pipe(tap(
        () => {
            this.posAuditLog = this.posAuditLog.filter(x => x.isWriting === false);
        },
        error => {
            this.notification.handleErrorSilent(error);
            //mark the array unpicked again so everything will be written on next update
            this.posAuditLog.forEach(x => {
                x.isWriting = false;
                x.writingTime = null;
            });
        })
    )
}



}
