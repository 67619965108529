import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReportsService } from '../shared/reports.service';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { NotificationService } from '../shared/notification.service';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.less']
})
export class InvoiceComponent implements OnInit, OnDestroy {

  paramsSub: Subscription;

  constructor(
    private reportsService: ReportsService,
    private route: ActivatedRoute,
    private notification: NotificationService
  ) { }

  ngOnDestroy(): void {
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(
      params => {
        this.reportsService.invoiceBase64(params['ref']).subscribe(
          val => {
            let title = "INVOICE";
            if (val.title !== undefined) title = val.title;
            window.document.write("<title>" + title + "</title><iframe width='100%' height='100%' src='data:application/pdf;base64, " + val.content + "'></iframe>")
            
          },
          err => {
            this.notification.handleError(err);
          }
        )
      }
    )
  }

}
