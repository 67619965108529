<div style="text-align: left;">
    <h3>Selected Date: <span class="boldText">{{displayDate}}:</span></h3>
</div>
<nz-spin [nzIndicator]="loadingTemplate" [nzSpinning]="isLoading">


    <nz-list nzItemLayout="horizontal">

        <a (click)="onItemClick(i)" *ngFor="let i of slots" style="text-decoration: none;">
            <nz-list-item>Time: {{i.slotTime.toString().padStart(2,"0").substring(0,5)}}<br><small><i>{{i.openSlots}} spaces open</i></small></nz-list-item>
        </a>
    </nz-list>
    <div *ngIf="slots.length === 0" style="padding-top: 20px;">
        <i nz-icon nzType="minus-circle" nzTheme="outline" style="color: darkred;"></i>
        <br />
        <h3>No Open Time Slots</h3>
    </div>
</nz-spin>

<ng-template #loadingTemplate>
    <app-loading></app-loading>
</ng-template>