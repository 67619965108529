import { Component, Inject, Input, LOCALE_ID, OnInit } from '@angular/core';
import { Bookingday } from 'src/app/models/booking.models';
import { NotificationService } from 'src/app/shared/notification.service';
import { BookingService } from '../booking.service';
import { SharedService } from 'src/app/shared/shared.service';


@Component({
  selector: 'app-booking-calendar-month',
  templateUrl: './booking-calendar-month.component.html',
  styleUrls: ['./booking-calendar-month.component.less']
})
export class BookingCalendarMonthComponent implements OnInit {

  isLoading = false;
  @Input() clientId: number;
  bookingSlotWeeks: Bookingday[][] = [];
  isMobile: boolean = false;

  constructor(private bookingService: BookingService,
              private notification: NotificationService,
              private sharedServices: SharedService) { }

  ngOnInit(): void {

    //check Device
    this.isMobile =  this.sharedServices.isMobile();

    this.getDaysFromAPI();
  }


  onValueChange(value: Date): void {
    this.bookingService.selectedDate = value;
  }

  // onPanelChange(change: { date: Date; mode: string }): void {
  //   // console.log(`Current value: ${change.date}`);
  //   // console.log(`Current mode: ${change.mode}`);
  // }

  // onCheckDisabledDate(date: Date): boolean{
  //   return this.bookingService.checkDate(date);
  // }

  // getSlots(date: Date) {
  //   return this.bookingService.getDateSlotCount(date);
  // }

  private getDaysFromAPI() {
    this.isLoading = true;
    this.bookingService.getDaysWithDate(this.clientId)
      .subscribe(
        data => {
          this.loadDays(data);
        },
        err => {
          this.notification.handleError(err);
        }
    );
  }

  private loadDays(data: Bookingday[]): void{
    //Display the days correctly
    //this.bookingService.setEnabledDates(data);

    //pad the start of the array to enable each week to have 7 days
    while (data[0].dow !== 1) {
      let newDate = new Date(data[0].date);
      newDate.setDate(new Date(data[0].date).getDate() - 1);
      let item = new Bookingday(newDate, data[0].dow-1, '', -1); //Make slots -1 to hide, or 0 to show but disable
      data.unshift(item)
    }

    //pad the end of the array
    for (let index = data[data.length - 1].dow; index < 7; index++) {
      let newDate = new Date(data[data.length - 1].date);
      newDate.setDate(new Date(data[data.length - 1].date).getDate() + 1);
      let item = new Bookingday(newDate, index + 1, '', 0); //Make slots -1 to hide, or 0 to show but disable
      data.push(item);

    }
    //split the array into an array of arrays, with 7 days (one week) per array
    this.bookingSlotWeeks = [];
    for (let index = 0; index < data.length; index += 7) {
      const week = data.slice(index, index + 7);
      this.bookingSlotWeeks.push(week)
    }

    this.isLoading = false;
  }

}
