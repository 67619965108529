import { formatDate } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable, LOCALE_ID } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Bookingday, BookingSlot } from '../models/booking.models';
import { Quote } from '../models/quote';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  private apiUrl = 'v1/bookings/';

  //#region PUBLIC VARIABLES
  public selectedDateChanged = new Subject<Date>();
  public selectedTime: string;
  public selectedQuote: Quote = new Quote();
  //#endregion PUBLIC VARIABLES

  //#region PROPERTIES
  private _selectedDate: Date = new Date(); 

  public get selectedDate() : Date {
    return this._selectedDate;
  }
  public set selectedDate(v : Date) {
    this._selectedDate = v;
    this.selectedDateChanged.next(v);
  }

  public get selectedDateStr() : string {
    return formatDate(this._selectedDate,"yyyy-MM-dd", this.locale);
  }
  //#endregion PROPERTIES

  // #region PRIVATE VARIABLES
  private dateList: {[id: string] : Bookingday} = {};
  // private clientId: number = 13; //#TODO
  // #endregion PRIVATE VARIABLES

  // #region CTOR
  constructor(@Inject(LOCALE_ID) public locale: string, private http: HttpClient) { }
  // #endregion CTOR

  //#region API CALLS
  getDays(startDate: Date, endDate: Date, clientId: number): Observable<Bookingday[]> {
    const APIEndpoint = environment.api_url + this.apiUrl  + `days?from=${formatDate(startDate,"yyyy-MM-dd", this.locale)}&to=${formatDate(endDate,"yyyy-MM-dd",this.locale)}&clientId=${clientId}`    
    return this.http.get<Bookingday[]>(APIEndpoint);  
  }

  getDaysWithDate(clientId: number): Observable<Bookingday[]> {
    const APIEndpoint = environment.api_url + this.apiUrl + `days?forDate=${formatDate(this.selectedDate,"yyyy-MM-dd", this.locale)}&clientId=${clientId}`;    
    return this.http.get<Bookingday[]>(APIEndpoint);  
  }

  getSlots(clientId: number) {
    const APIEndpoint = environment.api_url + this.apiUrl + `slots?bookingDate=${formatDate(this.selectedDate,"yyyy-MM-dd", this.locale)}&clientId=${clientId}`;   
    return this.http.get<BookingSlot[]>(APIEndpoint);  
  }

  confirmBooking() {
    const APIEndpoint = environment.api_url + this.apiUrl + `${this.selectedQuote.quoteRef}/confirmBooking`;   
    return this.http.post(APIEndpoint,{quoteId: this.selectedQuote.quoteId, date: this.selectedDateStr, time: this.selectedTime })
  }
  //#endregion API CALLS

  // #region PUBLIC METHODS
  // checkDate(date: Date) : boolean {   
  //   return ((this.dateList[formatDate(date,"yyyy-MM-dd", this.locale)]) === undefined)
  // }

  // setEnabledDates(dates: Bookingday[]): void {    
  //   this.dateList = {};
  //   for (var d of dates) {
  //     if (d.slots > -1) {        
  //       this.dateList[formatDate(d.date,"yyyy-MM-dd", this.locale)] = d;
  //     }
  //   }    
  // }

  // getDateSlotCount(date: Date): number {
  //   return (this.dateList[formatDate(date,"yyyy-MM-dd", this.locale)]) ? (this.dateList[formatDate(date,"yyyy-MM-dd", this.locale)]).slots : -1; 
  // }
  //#endregion PUBLIC METHODS

}
