import { Component } from '@angular/core';
import { QuoteService } from '././quote/quote.service';
import { ThemeService } from './theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.less']
})

export class AppComponent {
  title = 'StockfinderB2C';

  //Menu
  isCollapsed = true;
  isVisible = true;
  menuCollapsedWidth = 54;
  menuUnCollapsedWidth = 230;

  constructor(public quoteService: QuoteService,public themeService:ThemeService) {
  }

  ngOnInit(): void {

  }

}
