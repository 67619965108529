
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';


//Internationalisation
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';

//NG-Zorro
import { NzNotificationModule } from 'ng-zorro-antd/notification';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzAlertModule } from 'ng-zorro-antd/alert';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzCalendarModule } from 'ng-zorro-antd/calendar';
import { NzListModule } from 'ng-zorro-antd/list';
import { NzResultModule } from 'ng-zorro-antd/result';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzIconModule } from 'ng-zorro-antd/icon';



//Components
import { AppComponent } from './app.component';
import { ViewQuoteComponent } from './quote/view-quote/view-quote.component';
import { QuoteComponent } from './quote/quote.component';
import { ModifyQuoteComponent } from './quote/modify-quote/modify-quote.component';
import { PublicQuoteComponent } from './public-quote/public-quote.component';
import { BookingCalendarMonthComponent } from './booking/booking-calendar-month/booking-calendar-month.component';
import { BookingComponent } from './booking/booking.component';
import { BookingCalendarDayComponent } from './booking/booking-calendar-day/booking-calendar-day.component';
import { LoadingComponent } from './shared/loading/loading.component';
import { BookingSuccessComponent } from './booking/booking-success/booking-success.component';
import { CalendarDayBlockComponent } from './booking/booking-calendar-month/calendar-day-block/calendar-day-block.component';
import { StockReservedComponent } from './quote/stock-reserved/stock-reserved.component';
import { HttpInterceptorService } from './http-interceptor.service';
import { LinkComponent } from './link/link.component';
import { CallBackComponent } from './quote/call-back/call-back.component';
import { InvoiceComponent } from './invoice/invoice.component';
import { ModalContentComponent } from './shared/modal-content/modal-content.component';
import { LinkWhatsappComponent } from './link-whatsapp/link-whatsapp.component';




registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    ViewQuoteComponent,
    QuoteComponent,
    ModifyQuoteComponent,
    PublicQuoteComponent,
    BookingCalendarMonthComponent,
    BookingComponent,
    BookingCalendarDayComponent,
    LoadingComponent,
    BookingSuccessComponent,
    CalendarDayBlockComponent,
    StockReservedComponent,
    LinkComponent,
    CallBackComponent,
    InvoiceComponent,
    ModalContentComponent,
    LinkWhatsappComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    NzLayoutModule,
    NzTableModule,
    NzInputModule,
    NzButtonModule,
    NzSpinModule,
    NzNotificationModule,
    NzFormModule,
    NzAlertModule,
    NzToolTipModule,
    NzModalModule,
    NzTabsModule,
    NzInputNumberModule,
    NzCalendarModule,
    NzListModule,
    NzResultModule,
    NzCardModule,
    NzIconModule

  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptorService, multi: true}],
  bootstrap: [AppComponent]
})
export class AppModule { }
