export enum AuditEntryType {
    ChangeQty = 11,
    CustomerViewedQuote = 34,
    CustomerAcceptedOption = 35,
    CustomerSavedQuote = 36,
    CustomerPrintedQuote = 37,
    CustomerUpdatedTheirDetails = 38,
    CustomerlinkedVehicletoQuote = 39,
    CustomerAddedServicestoQuote = 40,
    CustomerBookedaSlot = 41 ,
    CustomerReservedStock = 42,
    CustomerAcceptedQuote = 43,
    CustomerAddedTheirDetails = 45,
    CustomerRequestedCallBack= 52
  }

export class AuditEntry {
    quoteId: number;
    actionId: AuditEntryType;
    foreignKeyId: number;
    valuePrev: string;
    valueNew: string;
    comment: string;
    dateActioned: Date;
    clientId: number
    isWriting: boolean;
    writingTime: Date | null;
  
    constructor(quoteId: number, action: AuditEntryType, foreignId: number, comment: string = "", previousValue: string = "", newValue: string = "")
     {
        this.quoteId = quoteId;
        this.actionId = action;
        this.valuePrev = previousValue;
        this.valueNew = newValue;
        this.comment = comment;
        this.foreignKeyId = foreignId;
        this.dateActioned = new Date();
        this.isWriting = false;
        this.writingTime = null;    
    }
}

