import { Quote , AcceptOption , VehicleReg , CustomerVehicle,CustomerDetails,LineQty, CustomerAdd,ServiceLine , AcceptService, GeneratedQuote } from '../models/quote';
import { environment } from 'src/environments/environment';
import { ThemeService } from '../theme.service';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';
import { Globals } from '../shared/globals';
import { Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class QuoteService {

  private apiUrl = 'v1/bookings/';
  quote:Quote;

  constructor(private http: HttpClient,private themeService:ThemeService) { }


  //Quote Methods
  public getQuote(ref: string) : Observable<Quote> {
      return this.http.get<Quote>(environment.api_url + this.apiUrl + `quotedetails/${ref}`)
      .pipe(
              tap(res => { 
                this.quote = res;
                this.themeService.updateTheme(res.licenseId);   
              })
          );
  }

  public acceptQuote(ref: string) {
    return this.http.get<Quote>(environment.api_url + this.apiUrl + `quote/${ref}`);
  }

  public acceptQuoteTentatively(ref: string, date?: Date) {
    let URL = environment.api_url + this.apiUrl + `quote/${ref}/tentativelyAccept`;
    if (date) {
      URL = URL + `?followUpDate=${date}`
    }
    return this.http.post(URL, null);
  }

  public acceptOption(quoteRef,AcceptedOption: AcceptOption ) {
    return this.http.put<Quote>(environment.api_url + this.apiUrl + `quote/${quoteRef}`, AcceptedOption );
  }

  public requestedCallBack(quoteRef) {
    return this.http.get<Quote>(environment.api_url + this.apiUrl + `quote/${quoteRef}/callBackRequested`);
  }

  public acceptServices(quoteRef,AcceptedServices: AcceptService )
  {
    return this.http.put<Quote>(environment.api_url + this.apiUrl + `quote/services/${quoteRef}`, AcceptedServices);
  }

  public updateQtys(quotelines:LineQty[]){
    return this.http.post<LineQty>(environment.api_url + this.apiUrl + `quote/updateQuoteLines/`, quotelines );
  }

  public removeItem(quoteLineId:number)
  {
    return this.http.get(environment.api_url + this.apiUrl + `quote/removeItem/` + quoteLineId);
  }

  public updateDetails(customer:CustomerDetails)
  {
    return this.http.post(environment.api_url + this.apiUrl + `quote/updateCustomer/` + customer.customerId, customer)
  }

  public AddCustomer(customer:CustomerAdd )
  {
    return this.http.post(environment.api_url + this.apiUrl + `quote/addCustomer/`, customer)
  }

  public getLinkedVehicleReg(quoteId: number) {
    return this.http.get<VehicleReg>(environment.api_url + this.apiUrl + `quote/` + quoteId + `/vehicleReg`)
  }

  public getCustomerVehicleReg(customerId: number) {
    return this.http.get<VehicleReg[]>(environment.api_url + this.apiUrl + `quote/vehicleReg/` + customerId)
  }

  public linkRegToQuote(mappingId: number, quoteId: number) {
    return this.http.post(environment.api_url + this.apiUrl + `quote/` + quoteId + `/vehicleReg/` + mappingId, null)
  }

  public addCustomerVehicle(customerId: number, veh: CustomerVehicle) {
    return this.http.post<{mappingId: number}>(environment.api_url + this.apiUrl + `quote/vehicleReg/` + customerId, veh)
  }

  public addServices(lines: ServiceLine[])
  {
    return this.http.post(environment.api_url + this.apiUrl + `quote/addServices`, lines);
  }

  public generateQuote(token: string, params: string[]) {
    return this.http.get<GeneratedQuote>(environment.api_url + this.apiUrl + `quote/${token}/generate?` + params.join('&'))
  }

  public logViewedDate(quoteRef)
  {
    return this.http.get<Quote>(environment.api_url + this.apiUrl + `quote/${quoteRef}/logViewedDate`);
  }
  
  public numberInput(event): boolean {
    return Globals.funcs.numberOnly(event);
  }


}
