import { Quote, QuoteLine, CustomerVehicle, VehicleReg,CustomerDetails,LineQty, ServiceLine } from 'src/app/models/quote';
import { NotificationService } from 'src/app/shared/notification.service';
import { FormControl, FormGroup ,Validators} from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import { AuditService } from '../../shared/audit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { QuoteService } from '../quote.service';
import { Globals } from '../../shared/globals';
import { Subscription } from 'rxjs';
import { AuditEntry, AuditEntryType } from 'src/app/models/audit-entry';
import { resultType } from 'src/app/shared/modal-content/modal-content.component';

@Component({
  selector: 'app-modify-quote',
  templateUrl: './modify-quote.component.html',
  styleUrls: ['./modify-quote.component.less']
})
export class ModifyQuoteComponent implements OnInit {

  //Declarations - arrays
  tyreLines: QuoteLine[] = [];
  otherLines: QuoteLine[] = [];
  cashbackLines: QuoteLine[] = [];
  customerReg: VehicleReg[] = [];
  linkedReg: VehicleReg = new VehicleReg();
  linkedQtyItems = [23339,23340,23341,23342 ,23343,25466]

  //Declaration
  posQuote: Quote;
  otherTotal: number = 0;
  cashbackTotal: number = 0;
  paramsSub: Subscription;
  timer; //used for updating the QTY in the PosActions

  //Loaders
  isLoading = false;
  isUpdating = false;
  showRegModal = false;
  showAddVehicleModal = false;
  isLoadingAddVehicle = false;
  isLoadingLinkedReg = false;
  showUpdateCustomerVisible  = false;
  showServices = true;
  Services: number = 24;

  //Booleans
  showMain: boolean = false;
  showService: boolean = false;
  isMobile: boolean = false;



  //Forms
  addVehicleForm: FormGroup = new FormGroup({
    vehReg: new FormControl('',Validators.required),
    vehWidth: new FormControl('',Validators.required),
    vehProfile: new FormControl('',Validators.required),
    vehRim: new FormControl('',Validators.required)
  });

  customerForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    email: new FormControl('',Validators.required),
    cell: new FormControl(0,Validators.required),
  });


  constructor(private router: Router,
    private route: ActivatedRoute,
    private quoteService: QuoteService,
    private notification: NotificationService,
    private auditService: AuditService,
    private sharedServices: SharedService
    ) { }


  ngOnInit(): void {

        //check Device
       this.isMobile =  this.sharedServices.isMobile();

        //get quote details
        this.posQuote = new Quote();
        this.tyreLines = QuoteLine[""];
        this.otherLines = QuoteLine[""];
      
        sessionStorage.setItem('loaded','Yes');
        this.paramsSub = this.route.params.subscribe(
          params => {

            this.isLoading = true;
            this.quoteService.getQuote(params["ref"]).subscribe(
              val => {
                let Firstload = sessionStorage.getItem('loaded')!;
                this.isLoading = false;
                this.posQuote = val;
                this.tyreLines = this.posQuote.quoteItems.filter(e => e.stockType === "TYRE");
                this.otherLines = this.posQuote.quoteItems.filter(e => e.stockType !== "TYRE");
                this.otherTotal = this.otherLines.reduce((sum, current) => sum + (current.price * current.qty), 0);
                this.cashbackLines = this.otherLines.filter(e => [29717, 29721, 33166, 33167, 33168, 33169].includes(e.msfId));
                this.cashbackTotal = this.cashbackLines.reduce((sum, current) => sum + (current.price * current.qty), 0);
                this.otherLines = this.otherLines.filter(e => ![29717, 29721, 33166, 33167, 33168, 33169].includes(e.msfId));

                //Welcome Customer
                if(Firstload == null || Firstload == undefined )
                {
                  this.notification.ShowAlert({type: resultType.Success, 
                    title: "Quote Portal", 
                    htmlMessage: ' Here, you have the ability to edit both the quoted items and your contact information.' ,
                    maskClosable: false, 
                    autoCloseInMilliseconds: 8000});                   
                }

                //link Customer to form
                this.customerForm.controls.name.setValue(this.posQuote.custName);
                this.customerForm.controls.cell.setValue(this.posQuote.custCell);
                this.customerForm.controls.email.setValue(this.posQuote.custEmail);

                //Choose table to show
                if(this.tyreLines.length != 0 )
                {
                   this.showMain = true;
                }
                else
                {
                  this.showService = true;
                }

                //Link Vreg
                this.quoteService.getLinkedVehicleReg(this.posQuote.quoteId).subscribe(
                  val => {
                    this.linkedReg = val;
                    this.isLoading = false;
                  },
                  error => {
                    this.notification.handleError(error)
                  }
                )

                //Check Services
                if(this.otherLines.length > 0)
                {
                  this.showServices = false;
                }

                //Process Removeables
                this.filterRemoveables()

                //Disable Bookings
                //this.posQuote.bookingsEnabled=false;

              },
              error => {
                this.isLoading = false;
              }
            )
          }
        )
        
  }

  filterRemoveables()
  {
    for(var i =0; i < this.otherLines.length; i++)
    {
      if(this.otherLines[i].msfId == 25466 || this.otherLines[i].msfId == 23337)
      {
        this.otherLines[i].removeable = true;
      }
      if(this.otherLines[i].description.startsWith("W"))
      {
        this.otherLines[i].description = this.otherLines[i].description.substring(0,15)
      }
    }
  }

  filterCashback(line: QuoteLine): QuoteLine[] {
    if (["MICHELIN","BFGOODRICH","BFPASSENGER"].includes(line.brand )) {
      if (this.cashbackLines.length > 0)
        return this.cashbackLines.concat(this.otherLines);
      else
        return this.otherLines;
    }
    return this.otherLines;
  }

  calcOtherTotal(line: QuoteLine): number {
    if (["GOODYEAR","MICHELIN","BFGOODRICH","PIRELLI","DUNLOP"].includes(line.brand )) {
      if (this.cashbackLines.length > 0)
        return this.otherTotal;
      else
        return this.otherTotal - this.cashbackTotal;
    }
    return this.otherTotal - this.cashbackTotal;
  }

  totalPriceInc(): string {
    if (!this.posQuote.quoteItems)
      return "";

    let total = 0;
    for (let line of this.posQuote.quoteItems)
    {
      var sum = line.price * line.qty
      total += sum;
    }
    return total.toFixed(2).toString();
  }


  updateLineItem(index: number, event: any)
  {
    const prevQty = this.tyreLines[index].qty;
    this.tyreLines[index].qty = event;
    let lines = [] as LineQty[];

    let line = new LineQty();
        line.quoteLineId = this.tyreLines[index].quoteLineId;
        line.qty = event;
    lines.push(line)

    for(var i = 0; i < this.otherLines.length; i++)
    {
      for(var j = 0; j < this.linkedQtyItems.length; j++)
      {
        if(this.otherLines[i].msfId == this.linkedQtyItems[j])
        {
          this.otherLines[i].qty = event;
          let line = new LineQty();
          line.quoteLineId = this.otherLines[i].quoteLineId;
          line.qty = event;
          lines.push(line)
        }
      }
    }

    clearTimeout(this.timer);//to restart the timeout

    this.auditService.addAction(new AuditEntry(this.posQuote.quoteId, AuditEntryType.ChangeQty, this.tyreLines[index].msfId,this.tyreLines[index].description,prevQty.toString(),event));

    this.timer = setTimeout(
    () =>
      {
        //Update DB
        this.auditService.saveActions();
        //this means that only after 1.5 sec will the db be updated NB
        this.quoteService.updateQtys(lines).subscribe(
          val => {

          },
          error => {
            this.notification.handleError(error)
          }
        )
      },
    1500);




  }

  removeItem(exItem:QuoteLine){
    this.quoteService.removeItem(exItem.quoteLineId).subscribe(
      val => {
         this.notification.showSuccess(exItem.description + " Removed");
         this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);
        this.showRegModal = false;
      }
    )
  }


  //Vreg
  onRegClick() {
    this.showRegModal = true;
    this.isLoadingLinkedReg = true;
    this.quoteService.getCustomerVehicleReg(this.posQuote.custId).subscribe(
      val => {
        this.customerReg = val;
        this.isLoadingLinkedReg = false;
      },
      error => {
        this.notification.handleError(error);
        this.showRegModal = false;
      }
    )
  }

  linkRegToQuote(mappingId: number) {
    this.showRegModal = false;
    this.showAddVehicleModal = false;
    this.isLoading = true;
    this.quoteService.linkRegToQuote(mappingId, this.posQuote.quoteId).subscribe(
      val => {
        this.quoteService.getLinkedVehicleReg(this.posQuote.quoteId).subscribe(
          val => {
            this.linkedReg = val;
            this.isLoading = false;
          },
          error => {
            this.notification.handleError(error)
          }
        )
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  onAddVehicleClick() {
    if(this.tyreLines.length > 0)
    {
      this.addVehicleForm.patchValue({
        vehWidth: parseInt(this.tyreLines[0].width),
        vehProfile: parseInt(this.tyreLines[0].profile),
        vehRim: parseInt(this.tyreLines[0].rim)
      })
    }

    this.showAddVehicleModal = true;
  }

  addVehicle() {
    if (this.addVehicleForm.valid)
    {
      this.isLoadingAddVehicle = true;
      let veh: CustomerVehicle = new CustomerVehicle()
      veh.vehReg = this.addVehicleForm.value.vehReg;
      veh.vehWidth = this.addVehicleForm.value.vehWidth;
      veh.vehProfile = this.addVehicleForm.value.vehProfile;
      veh.vehRim = this.addVehicleForm.value.vehRim;

      this.quoteService.addCustomerVehicle(this.posQuote.custId, veh).subscribe(
        val => {
          this.isLoadingAddVehicle = false;
          this.linkRegToQuote(val.mappingId);
          this.notification.showSuccess("Vehicle Linked");
          //action audit
          this.auditService.addAction(new AuditEntry(this.posQuote.quoteId, AuditEntryType.CustomerlinkedVehicletoQuote, 0));
          this.auditService.saveActions();
        },
        error => {
          this.isLoadingAddVehicle = false;
          this.notification.handleError(error);
        }
      )
    }
    else
    {
      Object.values(this.addVehicleForm.controls).forEach(control => {
        if (control.invalid)
        {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

 //Customer Details
 updateCustomer() {
  if (this.customerForm.valid)
  {
    if(this.customerForm.value.cell.length < 10)
    {
        this.notification.ShowAlert({type: resultType.Error, 
          title: "Cell Number is Not Valid", 
          htmlMessage: 'Please check that number contains 10 digits' ,
          maskClosable: true, 
          autoCloseInMilliseconds: 2000}); 
        return;
    }

    if( !Globals.consts.EmailPattern.test(this.customerForm.value.email) == true)
    {
        this.notification.ShowAlert({type: resultType.Error, 
          title: "Incorrect Email Pattern", 
          htmlMessage: 'Please check that the email is in the correct format, name@domain.anything ' ,
          maskClosable: true, 
          autoCloseInMilliseconds: 5000}); 
        return;
    }

    let customer: CustomerDetails = new CustomerDetails()
    customer.customerId = this.posQuote.custId;
    customer.name = this.customerForm.value.name;
    customer.email =this.customerForm.value.email;
    customer.cell = this.customerForm.value.cell;

    this.quoteService.updateDetails(customer).subscribe(
      val => {
         this.showUpdateCustomerVisible = false;
         this.notification.showSuccess("Details Updated");
         this.auditService.addAction(new AuditEntry(this.posQuote.quoteId, AuditEntryType.CustomerUpdatedTheirDetails, 0));
         this.auditService.saveActions();
         this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }
  else
   {
    Object.values(this.customerForm.controls).forEach(control => {
      if (control.invalid)
      {
        control.markAsDirty();
        control.updateValueAndValidity({ onlySelf: true });
      }
    });
  }
 }

 numberInput(event): boolean {
  return Globals.funcs.numberOnly(event);
 }

  //Services
  AddServices()
  {
    let services = [23337,23340,23342,25466];
    let ServiceLines = [] as ServiceLine[];
    //Add Service
    services.forEach(element => {
      let otherLine = new ServiceLine();
      otherLine.quoteId = this.posQuote.quoteId;
      otherLine.sellerId = this.posQuote.sellerId;
      otherLine.msfid = element;
      otherLine.qty = 1;
      ServiceLines.push(otherLine);
    });

    //Filter Qty
    for(var i = 0; i < ServiceLines.length; i++)
    {
      for(var j = 0; j < this.linkedQtyItems.length; j++)
      {
        if(ServiceLines[i].msfid == this.linkedQtyItems[j])
        {
          ServiceLines[i].qty = this.tyreLines[0].qty;
        }
      }
    }

    //Add to Quote
    this.quoteService.addServices(ServiceLines).subscribe(
      val => {
        this.ngOnInit();
        this.notification.showSuccess("Successfully Added Services to Quote");
        this.auditService.addAction(new AuditEntry(this.posQuote.quoteId, AuditEntryType.CustomerAddedServicestoQuote, 0));
        this.auditService.saveActions();
      },
      error => {
        this.notification.handleError(error);
      });

  }


 //Completing Actions
 bookSlot()
 {
  // this.auditService.addAction(new AuditEntry(this.posQuote.quoteId, AuditEntryType.CustomerBookedaSlot, 0));
  // this.auditService.saveActions();
   this.router.navigate(["/quote",this.posQuote.quoteRef,"booking"]);
 }

 reserveStock()
 {
  this.auditService.addAction(new AuditEntry(this.posQuote.quoteId, AuditEntryType.CustomerReservedStock, 0));
  this.auditService.saveActions();
  this.quoteService.acceptQuoteTentatively(this.posQuote.quoteRef).subscribe(
    () => {
      this.router.navigate(["/quote", this.posQuote.quoteRef, "reserved"]);
    },
    error => {
      this.notification.handleError(error);

    })


 }

}
