import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationService } from 'src/app/shared/notification.service';
import {  AuditEntry, AuditEntryType} from 'src/app/models/audit-entry'; //PosActionLogEntry,
import { Quote, QuoteLine , AcceptOption, CustomerAdd,AcceptService, LineQty } from 'src/app/models/quote';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SharedService } from 'src/app/shared/shared.service';
import { AuditService } from '../../shared/audit.service';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteService } from '../quote.service';
import { Globals } from '../../shared/globals';
// import * as html2pdf from 'html2pdf.js'
import { Subscription } from 'rxjs';
import { ReportsService } from 'src/app/shared/reports.service';
import { resultType } from 'src/app/shared/modal-content/modal-content.component';



@Component({
  selector: 'app-view-quote',
  templateUrl: './view-quote.component.html',
  styleUrls: ['./view-quote.component.less']
})
export class ViewQuoteComponent implements OnInit, OnDestroy {

  @ViewChild('pdfElement') pdfElement: ElementRef;
  private paramsSub: Subscription;
  isLoading: boolean = true;
  posquote: Quote = new Quote()

  tyreLines: QuoteLine[] = [];
  otherLines: QuoteLine[] = [];
  lines: QuoteLine[] = [];

  cashbackLinesGYPD: QuoteLine[] = [];
  cashbackLinesMsfids: QuoteLine[] = [];
  cashbackLines: QuoteLine[] = [];
  
  otherTotal: number = 0;
  cashbackTotalGYPD: number = 0;
  cashbackTotalMsfids: number = 0;
  

  //Display
  showAccepted: boolean = true;
  showAddDetails: boolean = false;
  hide: boolean = false;
  isSalesman: boolean = false;
  salesmanId: number = 0;
  isMobile: boolean = false;
  timer; //used for updating the QTY in the PosActions
  linkedQtyItems = [23339,23340,23341,23342 ,23343,25466]



  //Form
  customerForm: FormGroup = new FormGroup({
    name: new FormControl('', Validators.required),
    cell: new FormControl('', Validators.required),
    email: new FormControl(''),
  });




  constructor(  private router: Router,
                private route: ActivatedRoute,
                public quoteService: QuoteService,
                private notification: NotificationService,
                private auditService: AuditService,
                private sharedServices: SharedService,
                private reports: ReportsService
                ) { }

  ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }

  ngOnInit(): void {
    //check Device
   this.isMobile =  this.sharedServices.isMobile();
    //get quote details
    this.paramsSub = this.route.params.subscribe(
      params => {
        this.isLoading = true;
        this.isSalesman = params["salesman"] !== undefined;
        if (this.isSalesman)
        {
          this.salesmanId = params["salesman"] as number;
        }
        
        this.quoteService.getQuote(params["ref"]).subscribe(
          val => {
            this.posquote = val;
            
            //split the line items
            this.tyreLines = Globals.funcs.sortByKeyAsc(this.posquote.quoteItems.filter(e => e.stockType === "TYRE"),"rank");

            this.otherLines = this.posquote.quoteItems.filter(e => e.stockType !== "TYRE");
            
            this.cashbackLinesMsfids = this.otherLines.filter(e => this.cashbackMsfids.includes(e.msfId));
            this.cashbackLinesGYPD = this.otherLines.filter(e => this.cashbackMsfids.includes(e.msfId));
            
            this.cashbackTotalGYPD = this.cashbackLinesGYPD.reduce((sum, current) => sum + (current.price * current.qty), 0);
            this.cashbackTotalMsfids = this.cashbackLinesMsfids.reduce((sum, current) => sum + (current.price * current.qty), 0);
            this.otherLines = this.otherLines.filter(e => !this.cashbackLinesMsfids.includes(e)).filter(e => !this.cashbackLinesGYPD.includes(e));
            this.otherTotal = this.otherLines.reduce((sum, current) => sum + (current.price * current.qty), 0);

            this.isLoading = false;
            if ((this.posquote.stateId === 3 || this.posquote.statusId == 18)  && !this.isSalesman)
            {
              this.router.navigate(["/quote", this.posquote.quoteRef, "reserved"]);
              return;
            }
            if(this.posquote.custId == 14041 || this.posquote.custId == 0 )
            {
              this.showAddDetails = true;
              this.posquote.custName = '';
              this.posquote.custEmail = '';
              this.posquote.custCell = '';
              return;
            }
            
            if (!this.isSalesman) 
            {            
              this.quoteService.logViewedDate(this.posquote.quoteRef).subscribe(res => {
                this.auditService.addAction(new AuditEntry(this.posquote.quoteId, AuditEntryType.CustomerViewedQuote, 0));
                this.auditService.saveActions();
              });
            }

            //Process Removeables
            this.filterRemoveables()

            //Process cashBacks
            this.CashBackFilter(this.tyreLines);

            //Merge all arrays to get final result
            
            this.lines = this.tyreLines.concat(this.otherLines.concat(this.cashbackLines));

          },
          error => {
            this.notification.showError(error)
            this.isLoading = false;
          }
        )
      }
    )
  }

  //This is version 2 based on the new Staggered fitment. All of this will be replaced when cashBacks are introduced in AutoQuote

  CashBackFilter(line: QuoteLine []){
    for(var i = 0; i < line.length; i++){
      if (this.cashbackBrandsGYPD.includes(line[i].brand)) { //There is a qualifying brand, this includes all the msfid cashbacks as all msfids are part of the qualifying brand (BFG)
        if (this.cashbackMsfids.includes(line[i].msfId) && this.cashbackLinesMsfids.length > 0) { //There is a qualifying item, and the cashback item exists in the quote

          var cashBack = JSON.parse(JSON.stringify(this.cashbackLinesMsfids));
          cashBack.forEach(val => val.optionId = line[i].optionId);
          this.cashbackLines.push(...cashBack);
        }
        if (this.cashbackLinesGYPD.length > 0) {

          var cashBack = JSON.parse(JSON.stringify(this.cashbackLinesGYPD));
          cashBack.forEach(val => val.optionId = line[i].optionId);
          this.cashbackLines.push(...cashBack);

        }
      }
    }
    //Remove duplicate cashbacks when Staggered fitment is active
    this.cashbackLines = this.cashbackLines.filter((obj, index) => {
      return index === this.cashbackLines.findIndex(o => obj.msfId === o.msfId && obj.optionId === o.optionId);
    });
  }

  filterRemoveables()
  {
    for(var i =0; i < this.otherLines.length; i++)
    {
      if(this.otherLines[i].msfId == 25466 || this.otherLines[i].msfId == 23337)
      {
        this.otherLines[i].removeable = true;
      }
      if(this.otherLines[i].description.startsWith("W"))
      {
        this.otherLines[i].description = this.otherLines[i].description.substring(0,15)
      }
    }
  }

  cashbackBrandsGYPD = ["MICHELIN","BFGOODRICH","BFPASSENGER"];
  // cashbackBrandsMIBF = ["STOPPED123!"];
  cashbackMsfids = [29717, 29721, 33166, 33167, 33168, 33169];

  filterCashback(line: QuoteLine): QuoteLine[]| undefined {
    // if (this.cashbackBrandsGYPD.includes(line.brand )) {
    //   if (this.cashbackLinesGYPD.length > 0)
    //     return this.cashbackLinesGYPD.concat(this.otherLines);
    //   else
    //     return this.otherLines;
    // };
    // if (this.cashbackBrandsMIBF.includes(line.brand )) {
    //   if (this.cashbackLinesMIBF.length > 0)
    //     return this.cashbackLinesMIBF.concat(this.otherLines);
    //   else
    //     return this.otherLines;
    // };
    if (this.cashbackBrandsGYPD.includes(line.brand)) { //There is a qualifying brand, this includes all the msfid cashbacks as all msfids are part of the qualifying brand (BFG)
      if (this.cashbackMsfids.includes(line.msfId) && this.cashbackLinesMsfids.length > 0) { //There is a qualifying item, and the cashback item exists in the quote

        return this.cashbackLinesMsfids.concat(this.otherLines);
      }
      if (this.cashbackLinesGYPD.length > 0) {
        return this.cashbackLinesGYPD.concat(this.otherLines);
      }
    }
    return 
    // this.otherLines;
  }

  calcTotal(optionId: number): number{
    let sum = 0;
    this.lines.filter(e => e.optionId === null || e.optionId === optionId).map(x => sum += x.price * x.qty);

    return sum;
  }

  calcOtherTotal(line: QuoteLine): number {
    
    // if (this.cashbackBrandsGYPD.includes(line.brand )) {
    //   if (this.cashbackLinesGYPD.length > 0)
    //     return this.otherTotal + this.cashbackTotalGYPD;
    //   else
    //     return this.otherTotal;
    // }
    // if (this.cashbackBrandsMIBF.includes(line.brand )) {
    //   if (this.cashbackLinesMIBF.length > 0)
    //     return this.otherTotal + this.cashbackTotalMIBF;
    //   else
    //     return this.otherTotal;
    // }
    if (this.cashbackBrandsGYPD.includes(line.brand )) {
      if (this.cashbackMsfids.includes(line.msfId) && this.cashbackLinesMsfids.length > 0) { //There is a qualifying item, and the cashback item exists in the quote
        return this.otherTotal + this.cashbackTotalMsfids;
      }
      if (this.cashbackLinesGYPD.length > 0) {
        return this.otherTotal + this.cashbackTotalGYPD;
      }

    }

    return this.otherTotal;
  }

  updateLineItem(index: number, event: any)
  {
    const prevQty = this.tyreLines[index].qty;
    let lines = [] as LineQty[];
    for(var l = 0; l < this.tyreLines.length; l++)
    {
      this.tyreLines[l].qty = event;

      let line = new LineQty();
      line.quoteLineId = this.tyreLines[l].quoteLineId;
      line.qty = event;
      lines.push(line)

      for(var i = 0; i < this.otherLines.length; i++)
      {
        for(var j = 0; j < this.linkedQtyItems.length; j++)
        {
          if(this.otherLines[i].msfId == this.linkedQtyItems[j])
          {
            this.otherLines[i].qty = event;
            let line = new LineQty();
            line.quoteLineId = this.otherLines[i].quoteLineId;
            line.qty = event;
            lines.push(line)
          }
        }
      }
    }

    clearTimeout(this.timer);//to restart the timeout

    this.auditService.addAction(new AuditEntry(this.posquote.quoteId, AuditEntryType.ChangeQty, this.tyreLines[index].msfId,this.tyreLines[index].description,prevQty.toString(),event));

    this.timer = setTimeout(
    () =>
      {
        //Update DB
        this.auditService.saveActions();
        //this means that only after 1.5 sec will the db be updated NB
        this.quoteService.updateQtys(lines).subscribe(
          val => {

          },
          error => {
            this.notification.handleError(error)
          }
        )
      },
    1500);
  }


  @HostListener('window:focus', ['$event'])
  onFocus(): void {
    if(this.hide == true)
    {
      this.hide = false;
    }
  }

  checkCustomer()
  {
    if(this.posquote.custId == 14041 || this.posquote.custId == 0 )
    {
      this.showAddDetails = true;
      return false;
    }
    return true;
  }

  AddCustomer()
  {
    this.auditService.addAction(new AuditEntry(this.posquote.quoteId, AuditEntryType.CustomerAddedTheirDetails, 0));
    this.auditService.saveActions();
    if (this.customerForm.valid)
    {
      if(this.customerForm.value.cell.length < 10)
      {
          this.notification.ShowAlert({type: resultType.Error, 
            title: "Cell Number is Not Valid", 
            htmlMessage: 'Please check that number contains 10 digits' ,
            maskClosable: false, 
            autoCloseInMilliseconds: 2000});         
          return;
      }
      if(this.customerForm.value.email != "")
      {
        if( !Globals.consts.EmailPattern.test(this.customerForm.value.email) == true)
        {
            this.notification.ShowAlert({type: resultType.Error, 
              title: "Incorrect Email Pattern", 
              htmlMessage: 'Please check that the email is in the correct format, name@domain.anything' ,
              maskClosable: false, 
              autoCloseInMilliseconds: 5000});  
            return;
        }
      }

      let customer: CustomerAdd  = new CustomerAdd()
      customer.quoteId = this.posquote.quoteId;
      customer.name = this.customerForm.value.name;
      customer.email =this.customerForm.value.email;
      customer.cell = this.customerForm.value.cell;

      this.quoteService.AddCustomer(customer).subscribe(
        val => {
          if(this.callback == true)
          {
             this.callbackRequested()
          }
          else
          {
            if(this.selectedIndex != 99)
            {
              //Force true for customer check
              this.posquote.custId = 1;
              this.acceptOption(this.selectedIndex);
            }
            //this.router.navigate(['/quote/modify',this.posquote.quoteRef]);
            this.notification.showSuccess("Your Details Have Been Saved You can now Continue");
            this.showAddDetails = false;
            this.posquote.custName = '';
            this.posquote.custEmail = '';
            this.posquote.custCell = '';
            this.posquote.custId = val as number;
            this.ngOnInit();
          }
        },
        error => {
          this.notification.handleError(error);
        }
      )
    }
    else
    {
      Object.values(this.customerForm.controls).forEach(control => {
        if (control.invalid)
        {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }

  }

  printPDF() {
    let customer = this.checkCustomer();
    if(customer)
    {
      // this.notification.showSwalSuccess('','',500);
      // this.hide = true;
      // var element = this.pdfElement.nativeElement;
      //     element.style.display = "block";
      // var opt = {
      //   margin:       1,
      //   filename:     `QUOTE ${this.posquote.quoteRef}.pdf`,
      //   image:        { type: 'png', quality: 0.98 },
      //   html2canvas:  { scale: 1,  },
      //   jsPDF:        { unit: 'pt', format: 'a4', orientation: 'portrait' }
      // };


      // html2pdf().from(element).set(opt).toPdf().output('blob').then( (data: Blob) => {
      //   var fileURL = window.URL.createObjectURL(data);
      //   let tab = window.open(fileURL, "_blank");
      //   let k = setTimeout(() => {
      //     tab?.print();
      //     clearTimeout(k);
      //     element.style.display = "none";
      //   }, 500);
      // });
      this.reports.printQuotePDF(this.posquote.quoteRef);
      //action audit
      this.auditService.addAction(new AuditEntry(this.posquote.quoteId, AuditEntryType.CustomerPrintedQuote, 0));
      this.auditService.saveActions(this.isSalesman ? this.salesmanId : 0);

    }
  }

  callback:boolean = false;
  contactSalesman()
  {
    if(this.posquote.custName == "" ||  this.posquote.custCell == "" )
    {
      this.showAddDetails = true;
      this.callback = true;
      return;
    }
    else
    {
       this.callbackRequested()
    }
  }

  callbackRequested()
  {
    this.quoteService.requestedCallBack(this.posquote.quoteRef).subscribe(res=> {
      this.auditService.addAction(new AuditEntry(this.posquote.quoteId, AuditEntryType.CustomerRequestedCallBack, 0));
      this.auditService.saveActions();
      this.router.navigate(["/quote", this.posquote.quoteRef, "callback"]);
      this.notification.showSuccess("Great ! A Sales Representative Will Contact You");
    });
  }

  downloadPDF() {
    let customer = this.checkCustomer();
    if(customer)
    {
        // this.checkCustomer();
        // this.notification.showSwalSuccess('','',500);
        // this.hide = true;
        // var element = this.pdfElement.nativeElement;
        //               element.style.display = "block";

        // var opt = {
        //   margin:       1,
        //   filename:     `QUOTE ${this.posquote.quoteRef}.pdf`,
        //   image:        { type: 'png', quality: 0.98 },
        //   html2canvas:  { scale: 1,  },
        //   jsPDF:        { unit: 'pt', format: 'a4', orientation: 'portrait' }
        // };

        // html2pdf().from(element).set(opt).save().then(res =>{
        //   element.style.display = "none";});

        this,this.reports.downloadQuotePDF(this.posquote.quoteRef);

        //action audit

        this.auditService.addAction(new AuditEntry(this.posquote.quoteId, AuditEntryType.CustomerSavedQuote, 0));
        this.auditService.saveActions(this.isSalesman ? this.salesmanId : 0);
    }
  }

  acceptQuote()
  {
    let customer = this.checkCustomer();
    if(customer)
    {
      this.quoteService.acceptQuoteTentatively(this.posquote.quoteRef).subscribe(res => {
        if(res == null)
        this.notification.showSuccess("Quote Accepted");
        //action audit
        this.auditService.addAction(new AuditEntry(this.posquote.quoteId, AuditEntryType.CustomerAcceptedQuote, 0));
        this.auditService.saveActions();
        this.showAccepted = false;
        this.router.navigate(["/quote",this.posquote.quoteRef,"booking"]);
      },
      error => {
        this.notification.handleError(error);
        this.showAccepted = true;
      })
    }
  }

  selectedIndex : number = 99;
  acceptOption( index:number )
  {
    this.selectedIndex = index;
    let customer = this.checkCustomer();
    if(customer)
    {
      // Show Notification
      var Option = index;

      // Declare Model
      var AcceptedOption = new AcceptOption()

      // Set The Tyre Line Item
      
      AcceptedOption.Tyre = this.lines.filter(val => val.optionId == index && val.stockType.toLocaleUpperCase() === 'TYRE');

      // Set The Line Items "Other services etc"
      AcceptedOption.OtherItems = [] as QuoteLine[];

      AcceptedOption.OtherItems = this.lines.filter(val => (val.optionId == index || val.optionId == null) && (val.stockType.toLocaleUpperCase() !== 'TYRE'));

      if(this.otherLines != null || this.cashbackLinesGYPD)
      {
        AcceptedOption.OtherItems = this.otherLines.filter(val => (val.optionId == index || val.optionId == null));
        if (this.cashbackBrandsGYPD.includes(AcceptedOption.Tyre[0].brand.toString())) {
          AcceptedOption.OtherItems = AcceptedOption.OtherItems.concat(this.cashbackLinesGYPD)
        }
        
      }

      // Send To DB
      this.quoteService.acceptOption(this.posquote.quoteRef,AcceptedOption).subscribe(res=>{
        this.notification.showSuccess("Quote Option " + Option + " Accepted");
        this.auditService.addAction(new AuditEntry(this.posquote.quoteId, AuditEntryType.CustomerAcceptedOption, 0));
        this.auditService.saveActions();
        this.router.navigate(['/quote/modify',this.posquote.quoteRef]);
      },
      error => {
        this.notification.handleError(error);
      });
    }
  }

  acceptServiceQuote()
  {
    let customer = this.checkCustomer();
    if(customer)
    {

      //Declare Model
      var AcceptedServices = new AcceptService()

      //Set The Line Items "Other services etc"
      AcceptedServices.OtherItems = [] as QuoteLine[];
      AcceptedServices.OtherItems = this.otherLines;

      //Send To DB
      this.quoteService.acceptServices(this.posquote.quoteRef,AcceptedServices).subscribe(res=>{
        this.notification.showSuccess("Service Quote Accepted");
        //action audit
        this.auditService.addAction(new AuditEntry(this.posquote.quoteId, AuditEntryType.CustomerAcceptedOption, 0));
        this.auditService.saveActions();
        this.router.navigate(['/quote/modify',this.posquote.quoteRef]);
      },
      error => {
        this.notification.handleError(error);
      });
    }
  }

  removeItem(exItem:QuoteLine){
    this.quoteService.removeItem(exItem.quoteLineId).subscribe(
      val => {
         this.notification.showSuccess(exItem.description + " Removed");
         this.ngOnInit();
      },
      error => {
        this.notification.handleError(error);
      }
    )
  }

  filteredLines(): (number)[] {

    //this will return a list of all the option numbers in the array, dynamically calculated, with NULL at the first position
    return [...new Set(this.lines.filter(val => val.optionId !== null).map(item => item.optionId))].sort((a,b) => {
        if (a === null) return -1;
        if (b === null) return 1;
        return a-b;
      });
  }

  filteredItemsForOption(optionId: number): QuoteLine[] {

    return this.lines.filter(e => e.optionId === null || e.optionId === optionId);
  }
}