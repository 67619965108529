import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { LinkService } from './link.service';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.less']
})
export class LinkComponent implements OnInit, OnDestroy {

  private paramsSub: Subscription;
  public isLoading = true;
  public isSuccess = true;

  constructor(private route: ActivatedRoute, private linkService: LinkService, private router: Router) { }

  ngOnDestroy(): void {
    this.paramsSub.unsubscribe();
  }

  ngOnInit(): void {
    this.paramsSub = this.route.params.subscribe(
      params => {
        this.isLoading = true;
        this.linkService.getLink(params["linktoken"]).subscribe(
          val => {
            window.location.href = val.href; //this will enable us to redirect to any domain
            //this.router.navigateByUrl(val.href);
          },
          err => {
            this.isLoading = false;
            this.isSuccess = false;
          })
      },
      error => {
        this.isLoading = false;
        this.isSuccess = false;
      }
    )
  }

}
