import { Injectable } from '@angular/core';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { ModalContentComponent, resultType } from './modal-content/modal-content.component';



@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(private notification: NzNotificationService, private modal: NzModalService) { }

  public showError(message: string, title? : string): void {
    title = title == undefined ? 'Error' : title;
    this.notification.error(title, message);
  }

  public showWarning(message: string): void {
    this.notification.warning('Warning', message)
  }

  public showSuccess(message: string): void {
    this.notification.success('Success', message)
  }

  public showInfo(message: string): void {
    this.notification.info('Info', message)
  }

  public handleError(error: any, message? : string, title?: string): void {
    const msg = error.error.field ? error.error.field + " " + error.error.message : error.error.detail ? error.error.detail : error.statusText;
    this.showError(message == undefined ? msg : message + " :: " + msg, title);
  }

  public handleErrorSilent(error: any, message? : string, title?: string): void {
    const msg = error.error.field ? error.error.field + " " + error.error.message : error.error.detail ? error.error.detail : error.statusText;
  }

  public ShowBlockingPopup(message: string, title? : string, width?: string | number) {
    this.ShowAlert({type: resultType.Information, title: title ? title : "NOTICE", htmlMessage: message, btnText: "OK", maskClosable: false}, width)
  }
  
  public ShowAlert(opts: AlertModalOptions, width? : string | number) {
    let footerBtns: Array<any> = [];
    if (opts.btnText) {
      footerBtns = [{
        label: opts.btnText,
        type: "primary",
        onClick: e => this.modal.closeAll()
        
      }]
    }
    let ref = this.modal.create({
      nzTitle: "",
      nzContent: ModalContentComponent,
      nzFooter: footerBtns.length > 0 ? footerBtns : null,
      nzMaskClosable: opts.maskClosable === undefined ? true : opts.maskClosable,
      nzClosable: opts.closable === undefined ? true : opts.closable,
      nzWidth: width ? width : 600,
      nzData:{
        title: opts.title,
        message: opts.message,
        htmlMessage: opts.htmlMessage,
        notiType: opts.type
      }
    });
    if (opts.autoCloseInMilliseconds) {
      setTimeout(() => ref.destroy(), opts.autoCloseInMilliseconds);
      
    }
    return ref;
  }

  public ShowNonBlockingMessage(message: string) {
    this.notification.info('NOTICE', message, {nzDuration:0, nzClass: 'ws-noti'});
  }

}

export interface AlertModalOptions {
  type: resultType,
  title?: string, 
  closable?: boolean, 
  maskClosable?: boolean, 
  btnText?: string, 
  message? : string, 
  htmlMessage? :string, 
  autoCloseInMilliseconds? : number
}

