import { Injectable } from '@angular/core';


@Injectable({
  providedIn: 'root'
})
export class ThemeService {

  private currentTheme: string = 'default';

  constructor() {
    this.loadTheme(this.currentTheme, true);
   }

  public updateTheme(licenseId) {    
    let changeThemeTo: string = "default";
    switch (licenseId) {
      case 1: {
        changeThemeTo = 'tyremart'; 
        break;
      }
      case 2: {
        changeThemeTo = 'apex'; 
        break;
      }
      case 3: {
        changeThemeTo = 'bestdrive'; 
        break;
      }
      default: {
        changeThemeTo = 'default';
      }
    }     
    if (changeThemeTo !== this.currentTheme && changeThemeTo.length > 0){
      this.loadTheme(changeThemeTo);
    }  
  }

  themeLogo(): string {
    return this.currentTheme.replace("_dark","") + '.png';
  }
  
  loadTheme(themeName: string, firstLoad = false): any {
    this.loadCss(`${themeName}.css`, themeName).then(
      e => {
        if (!firstLoad) {
          this.removeUnusedTheme(this.currentTheme);
        }    
        this.currentTheme = themeName;   
      }
    );
  }

  private loadCss(href: string, id: string): Promise<Event> {
    return new Promise((resolve, reject) => {
      const style = document.createElement('link');
      style.rel = 'stylesheet';
      style.href = href;
      style.id = id;
      style.onload = resolve;
      style.onerror = reject;
      document.head.append(style);
    });
  }

  private removeUnusedTheme(theme: string): void {
    document.documentElement.classList.remove(theme);
    const removedThemeStyle = document.getElementById(theme);
    if (removedThemeStyle) {
      document.head.removeChild(removedThemeStyle);
    }
  }

}
