
<table
         [cellPadding]="0"
         [cellSpacing]="0"
         [ngStyle]="{
                      'margin-left': isMobile ? '-5%' : '',
                      'scale': isMobile ? '0.9':'1'
                    }" >
  <thead>
    <tr>
      <th>Su</th>
      <th>Mo</th>
      <th>Tu</th>
      <th>We</th>
      <th>Th</th>
      <th>Fr</th>
      <th>Sa</th>
    </tr>

  </thead>
  <tbody>
    <tr *ngFor="let week of this.bookingSlotWeeks">
      <td *ngFor="let day of week">
        <app-calendar-day-block [bookingDay]="day" *ngIf="day.slots > -1" (dayClicked)="onValueChange($event)"></app-calendar-day-block>
      </td>
    </tr>
  </tbody>
</table>
<!-- <app-calendar-day-block [date]="cDate" [slots]="1"></app-calendar-day-block>
<app-calendar-day-block [date]="cDate" [slots]="0"></app-calendar-day-block> -->
